import { create } from 'zustand'
import { QuotaType } from '../Types/quota'

type BillingStore = {
  quota: QuotaType
  setQuota: (quota: QuotaType) => void
}

export const useBillingStore = create<BillingStore>(set => ({
  quota: { max_product: 0, reviews_count: 0, remaining_reviews: 0 },
  setQuota: quota => set({ quota }),
}))
