import styled from 'styled-components'

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${p => p.theme.colors.blocks?.light};
  height: 45px;
`
