import React from 'react'
import { IconType } from 'Types/icon'
import { useTheme } from 'styled-components'

const OverviewNav = (props: IconType) => {
  const theme = useTheme()
  const { fill = theme.colors.icons.dark } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4226 19.8091V17.9528C10.4226 17.2955 10.9594 16.7626 11.6215 16.7626H12.5333C12.8513 16.7626 13.1562 16.888 13.3811 17.1112C13.6059 17.3344 13.7322 17.6371 13.7322 17.9528V19.8091C13.7302 20.0824 13.8381 20.3452 14.0321 20.5391C14.226 20.733 14.4899 20.8421 14.9049 20.8421H17.0895C17.8607 20.8441 18.6011 20.5413 19.1471 20.0007C19.6931 19.46 20 18.7259 20 17.9603V10.6247C20 10.0063 19.7238 9.41966 19.246 9.02288L13.6287 4.56915C12.6515 3.78826 11.2515 3.81348 10.3035 4.62903L4.81433 9.02288C4.31389 9.40796 4.01478 9.99633 4 10.6247V17.9528C4 19.5485 5.30306 20.8421 6.91046 20.8421H9.35947C9.93121 20.8421 10.3959 20.3842 10.4 19.8166L10.4226 19.8091Z"
        fill={fill}
      />
    </svg>
  )
}

export default OverviewNav
