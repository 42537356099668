import React from 'react'
import { IconType } from 'Types/icon'
import { useTheme } from 'styled-components'

const GroupNav = (props: IconType) => {
  const theme = useTheme()
  const { fill = theme.colors.icons.dark } = props
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5359 5.81185C11.0799 5.22786 10.3847 4.88387 9.63991 4.89173H8.37593C5.47198 4.89173 4 6.57184 4 9.88379V15.8917C4 18.9876 5.91197 20.8916 8.99192 20.8916H14.9998C18.0958 20.8916 19.9998 18.9876 19.9998 15.8997V12.0038C20.0077 8.7718 18.5678 7.19583 15.6158 7.19583H13.1598C12.7999 7.19583 12.4639 7.02783 12.2399 6.74784L11.5359 5.81185ZM8 15.1416C7.58579 15.1416 7.25 15.4774 7.25 15.8916C7.25 16.3058 7.58579 16.6416 8 16.6416H16C16.4142 16.6416 16.75 16.3058 16.75 15.8916C16.75 15.4774 16.4142 15.1416 16 15.1416H8Z"
        fill={fill}
      />
    </svg>
  )
}

export default GroupNav
