import React from 'react'
import {
  MarketplaceIcon,
  RatingWrapper,
  ReviewMarketplace,
  ReviewTitle,
  ReviewTopicWrapper,
  TopicsWrapper,
  Wrapper,
} from './styled'
import { getMarketplaceIcon } from 'Utils/marketplace'
import Text from 'Components/Kit/Text'
import Star from 'Assets/Star'
import { css, useTheme } from 'styled-components'
import { IReview, ReviewTopic } from 'Types/review'

type ReviewProps = {
  review: IReview
  topics?: ReviewTopic[]
}
const Review = ({ review, topics }: ReviewProps) => {
  const theme = useTheme()

  const getTopicBg = (topic: 'p' | 'n' | 'i') => {
    switch (topic) {
      case 'p':
        return {
          background: '#EAFAF3',
          borderColor: '#05DB61',
        }
      case 'n':
        return {
          background: '#FFF1F1',
          borderColor: '#FF4A22',
        }
      case 'i':
        return {
          background: '#EBF3FF',
          borderColor: '#2353FF',
        }
      default:
        return {
          background: '#EAFAF3',
          borderColor: '#05DB61',
        }
    }
  }

  return (
    <Wrapper>
      <ReviewTitle>
        <ReviewMarketplace>
          <MarketplaceIcon src={getMarketplaceIcon(review.marketplace)} />
          <Text fontSize={12} fontWeight={400} color={theme.colors.text.gray}>
            {review.marketplace}
          </Text>
        </ReviewMarketplace>
        <RatingWrapper>
          <Star width={14} height={14} viewBox={'0 0 20 20'} />
          <Text fontSize={12} fontWeight={500}>
            {review.rating.toFixed(1)}
          </Text>
        </RatingWrapper>
        <Text fontSize={12} fontWeight={400} color={theme.colors.text.gray}>
          {review.posted_at.slice(0, 10)}
        </Text>
      </ReviewTitle>
      <Text fontSize={14} fontWeight={400} styledFragment={css`width: 100%`}>
        {review.text}
      </Text>
      {topics && (
        <TopicsWrapper>
          {topics.map((topic, index) => (
            <ReviewTopicWrapper
              key={index}
              borderColor={getTopicBg(topic.sentiment).borderColor}
              background={getTopicBg(topic.sentiment).background}
            >
              <Text fontSize={12} fontWeight={400} color={theme.colors.text.gray}>
                {topic.name}
              </Text>
            </ReviewTopicWrapper>
          ))}
        </TopicsWrapper>
      )}
    </Wrapper>
  )
}

export default Review
