import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.colors.background};
  width: 100%;
  height: calc(100vh - 71px);
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 390px;
`
export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
`
