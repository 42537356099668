import React from 'react'
import { IconType } from 'Types/icon'
import { useTheme } from 'styled-components'

const Compare = (props: IconType) => {
  const theme = useTheme()
  const { fill = theme.colors.icons.dark } = props
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2072_25457)">
        <path
          d="M1.77778 -1C0.248503 -1 -1 0.248503 -1 1.77778V16.2222C-1 17.7515 0.248503 19 1.77778 19H16.2222C17.7515 19 19 17.7515 19 16.2222V1.77778C19 0.248503 17.7515 -1 16.2222 -1H1.77778ZM11.7778 4.55556C11.9251 4.55558 12.0664 4.61412 12.1706 4.71832C12.2747 4.8225 12.3333 4.96379 12.3333 5.11111C12.3333 5.25843 12.2747 5.39972 12.1706 5.50391L10.8967 6.77778H15.1111C15.2585 6.77778 15.3998 6.83631 15.504 6.9405C15.6081 7.04468 15.6667 7.18599 15.6667 7.33333C15.6667 7.48068 15.6081 7.62198 15.504 7.72617C15.3998 7.83036 15.2585 7.88889 15.1111 7.88889H10.8967L12.1706 9.16276C12.2747 9.26694 12.3333 9.40823 12.3333 9.55556C12.3333 9.70288 12.2747 9.84417 12.1706 9.94835C12.0664 10.0525 11.9251 10.111 11.7778 10.111C11.6305 10.111 11.4892 10.0525 11.385 9.94835L9.16276 7.72613C9.05857 7.62196 9.00002 7.48067 9 7.33333C9.00002 7.186 9.05857 7.04471 9.16276 6.94054L11.385 4.71832C11.4892 4.61412 11.6304 4.55558 11.7778 4.55556ZM6.22222 7.88889C6.36956 7.88891 6.51085 7.94746 6.61502 8.05165L8.83724 10.2739C8.88921 10.3253 8.93051 10.3865 8.95877 10.454C8.98633 10.5215 9.00035 10.5938 9 10.6667C8.99997 10.814 8.94143 10.9553 8.83724 11.0595L6.61502 13.2817C6.51083 13.3858 6.36954 13.4444 6.22222 13.4444C6.0749 13.4444 5.93361 13.3858 5.82943 13.2817C5.72527 13.1775 5.66675 13.0362 5.66675 12.8889C5.66675 12.7416 5.72527 12.6003 5.82943 12.4961L7.1033 11.2222H2.88889C2.74155 11.2222 2.60024 11.1637 2.49605 11.0595C2.39187 10.9553 2.33333 10.814 2.33333 10.6667C2.33333 10.5193 2.39187 10.378 2.49605 10.2738C2.60024 10.1696 2.74155 10.1111 2.88889 10.1111H7.1033L5.82943 8.83724C5.72527 8.73306 5.66675 8.59177 5.66675 8.44444C5.66675 8.29712 5.72527 8.15583 5.82943 8.05165C5.9336 7.94746 6.07489 7.88891 6.22222 7.88889Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_25457">
          <rect width="18" height="18" rx="6" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Compare
