import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import Layout from '../Layout'
import { PrivateRoutes, PublicRoutes } from './types'
import NotProtectedRoute from './NotProtectedRoute'
import Login from '../Widgets/Login'
import YandexOAuthToken from '../Widgets/YandexOAuthToken'
import Overview from '../Widgets/Overview'
import Item from '../Widgets/Item'
import Group from '../Widgets/Group'
import Reviews from '../Widgets/Reviews'
import Comparison from '../Widgets/Comparison'
import Subscription from '../Widgets/Subscription'
import React from 'react'
import SuccessPayment from '../Widgets/SuccessPayment'
import ProtectedRoute from './ProtectedRoute'
import SubscriptionRoute from './SubsciptionRoute'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path={'/'} element={<Navigate to={PublicRoutes.START} replace />} />
      <Route path={'/'} element={<NotProtectedRoute />}>
        <Route index path={PublicRoutes.START} element={<Login />} />
        <Route path={PublicRoutes.LOGIN_YANDEX} element={<YandexOAuthToken />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<SubscriptionRoute />}>
          <Route index path={PrivateRoutes.OVERVIEW} element={<Overview />} />
          <Route path={PrivateRoutes.CURRENT_PRODUCT} element={<Item />} />
          <Route path={PrivateRoutes.GROUP} element={<Group />} />
          <Route path={PrivateRoutes.REVIEWS} element={<Reviews />} />
          <Route path={PrivateRoutes.COMPARISON} element={<Comparison />} />
        </Route>
        <Route path={PrivateRoutes.SUBSCRIPTION} element={<Subscription />} />
        <Route path={PrivateRoutes.SUCCESS_PAYMENT} element={<SuccessPayment />} />
      </Route>
    </Route>,
  ),
)
