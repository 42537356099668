import { useFetch } from 'Hooks/useFetch'
import { ISimpleItem } from 'Types/product'
import { ISimpleTopic, ITopic, TopicsResponseType } from 'Types/topic'
import { IRating } from 'Types/rating'
import { ISentiment } from 'Types/sentiment'
import { IMarketplace } from 'Types/marketplace'
import { FourPItemType, SentimentResponseType } from 'Types/charts'
import { IReview, ReviewsResponseType } from 'Types/review'
import { NpsType } from 'Types/nps'
import { PopularTopicType } from 'Types/popularTopics'

type UseItemDataProps = {
  activeMarketplace: string
  activeSentimentPlotTopic: string
  date__gte: string
  date__lte: string
  id: string
  activeReviewsType: number
  activeReviewsTopic: string
  discussedTopicsType: number
}
export const useItemData = (props: UseItemDataProps) => {
  const {
    activeMarketplace,
    activeSentimentPlotTopic,
    date__lte,
    date__gte,
    id,
    activeReviewsType,
    activeReviewsTopic,
    discussedTopicsType,
  } = props

  const requiredRequestParams = {
    date__gte,
    date__lte,
  }

  const { data: item } = useFetch<ISimpleItem | null>(`products/${id}`, null)

  const { data: lineChartData } = useFetch<SentimentResponseType | null>(
    `plots/review_sentiment_over_time_plot`,
    null,
    {
      topic: activeSentimentPlotTopic,
      product: +id,
      marketplace: activeMarketplace,
      ...requiredRequestParams,
    },
  )

  const { data: reviews } = useFetch<ReviewsResponseType | null>(`reviews/`, null, {
    product: +id,
    limit: 6,
    is_positive: activeReviewsType === 0 ? 1 : 0,
    topic: activeReviewsTopic,
    marketplace: activeMarketplace,
  })

  const { data: topics } = useFetch<TopicsResponseType | null>(`topics/`, null, {
    product: +id,
    marketplace: activeMarketplace,
    ...requiredRequestParams,
  })

  const { data: discussedTopics } = useFetch<ISimpleTopic[]>('topics/top/', [], {
    product: +id,
    sentiment: discussedTopicsType === 0 ? 'positive' : 'negative',
    marketplace: activeMarketplace,
    ...requiredRequestParams,
  })

  const { data: rating } = useFetch<IRating | null>('products/rating/', null, {
    product: +id,
    ...requiredRequestParams,
  })

  const { data: sentiment } = useFetch<ISentiment>(
    'products/sentiment/',
    { score: 0, difference: 0 },
    {
      product: +id,
      ...requiredRequestParams,
    },
  )

  const { data: nps, isLoading: isNpsLoading } = useFetch<NpsType | null>(
    'products/nps/',
    null,
    {
      product: +id,
      ...requiredRequestParams,
    },
  )

  const { data: fourPDataPlace } = useFetch<FourPItemType | null>('/4p/place/', null, {
    product: +id,
    ...requiredRequestParams,
  })
  const { data: fourPDataPromotion } = useFetch<FourPItemType | null>(
    '/4p/promotion/',
    null,
    {
      product: +id,
      ...requiredRequestParams,
    },
  )
  const { data: fourPDataPrice } = useFetch<FourPItemType | null>('/4p/price/', null, {
    product: +id,
    ...requiredRequestParams,
  })
  const { data: fourPDataProduct } = useFetch<FourPItemType | null>(
    '/4p/product/',
    null,
    {
      product: +id,
      ...requiredRequestParams,
    },
  )

  const { data: popularTopics, isLoading: isPopularTopicsLoading } = useFetch<
    PopularTopicType[]
  >('plots/topics_donut_chart/', [], {
    product: +id,
    ...requiredRequestParams,
  })

  const { data: marketplaces } = useFetch<IMarketplace[]>('marketplaces/me/', [], {
    product: +id,
  })

  return {
    item,
    lineChartData,
    reviews,
    topics,
    discussedTopics,
    rating,
    sentiment,
    marketplaces,
    nps,
    popularTopics,
    fourPDataProduct,
    fourPDataPrice,
    fourPDataPromotion,
    fourPDataPlace,
  }
}
