import React from 'react'
import { ConfigProvider } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useFiltersStore } from 'Store/useFiltersStore'
import ruRU from 'antd/locale/ru_RU'
import 'dayjs/locale/ru'
import { StyledRangePicker } from './styled'

dayjs.locale('ru')
const CustomDatePicker = () => {
  const { activePeriod, setActivePeriod } = useFiltersStore()

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [startDate, endDate] = dates
      setActivePeriod(
        startDate ? startDate.format('YYYY-MM-DD') : '',
        endDate ? endDate.format('YYYY-MM-DD') : '',
      )
    } else {
      setActivePeriod('', '')
    }
  }

  return (
    <ConfigProvider locale={ruRU}>
      <StyledRangePicker
        value={[
          activePeriod.startDate ? dayjs(activePeriod.startDate) : null,
          activePeriod.endDate ? dayjs(activePeriod.endDate) : null,
        ]}
        onChange={handleDateChange}
        disabledDate={current => current && current > dayjs().endOf('day')}
        placeholder={['Дата начала', 'Дата окончания']}
      />
    </ConfigProvider>
  )
}

export default CustomDatePicker
