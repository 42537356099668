import React from 'react'

const FirstDecoration = () => {
  return (
    <svg
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9538 11.2889L18.0558 13.6356V18.3467L13.9538 20.6933L9.85185 18.3467V13.6356L13.9538 11.2889ZM13.9538 0L0 8V24L13.9538 32L27.9076 24V8L13.9538 0Z"
        fill="black"
      />
    </svg>
  )
}

export default FirstDecoration
