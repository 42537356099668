import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  width: 100%;
  gap: 8px;
  border-bottom: 1px solid ${p => p.theme.colors.blocks?.gray};
`
export const ReviewTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`
export const ReviewMarketplace = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const MarketplaceIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 6px;
`
export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`
export const TopicsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
`
export const ReviewTopicWrapper = styled.div<{
  background: string
  borderColor: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid ${p => p.borderColor};
  background: ${p => p.background};
  cursor: pointer;
`
