import React from 'react'

const DownloadFile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.8595 3.3335C11.0294 3.3335 11.1721 3.47761 11.1721 3.64918V5.85899C11.1721 7.11487 12.1913 8.14428 13.4415 8.15114C13.9511 8.15114 14.3656 8.15801 14.6714 8.15801L14.7871 8.1575C14.996 8.15591 15.2773 8.15114 15.5207 8.15114C15.6974 8.15114 15.8333 8.2884 15.8333 8.45997V13.9776C15.8333 15.6796 14.4607 17.059 12.7756 17.059H7.37377C5.60033 17.059 4.16663 15.6109 4.16663 13.8266V6.42859C4.16663 4.72663 5.53238 3.3335 7.23108 3.3335H10.8595ZM9.91381 8.67761C9.63522 8.67761 9.4042 8.90408 9.4042 9.19232V12.5002L8.32383 11.4021C8.12678 11.2031 7.80742 11.2031 7.61037 11.4021C7.41333 11.6011 7.41333 11.9237 7.61037 12.1296L9.55369 14.0992C9.60125 14.1472 9.65561 14.1815 9.71676 14.209C9.77791 14.2364 9.84586 14.2502 9.91381 14.2502C9.98176 14.2502 10.0429 14.2364 10.1041 14.209C10.1652 14.1815 10.2196 14.1472 10.2671 14.0992L12.2172 12.1296C12.4143 11.9237 12.4143 11.6011 12.2105 11.4021C12.0134 11.2031 11.694 11.2031 11.497 11.4021L10.4166 12.5002V9.19232C10.4166 8.90408 10.1924 8.67761 9.91381 8.67761ZM12.1583 3.95533C12.1583 3.65955 12.5137 3.51268 12.7162 3.72611C13.4514 4.49749 14.7349 5.84602 15.4524 6.59955C15.6509 6.80749 15.5054 7.15268 15.2187 7.15337C14.6609 7.15543 14.0024 7.15337 13.5288 7.14857C12.7773 7.14857 12.1583 6.52337 12.1583 5.76435V3.95533Z"
        fill="black"
      />
    </svg>
  )
}

export default DownloadFile
