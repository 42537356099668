import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks.light};
  padding: 40px;
`
export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(412px, 1fr));
  gap: 20px;
  width: 100%;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  background: ${p => p.theme.colors.blocks.lightGray};
  padding: 40px;
  border-radius: 40px;
  min-width: 412px;
  width: 100%;
  height: 300px;
`
