import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

export const SentimentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

export const SentimentType = styled.div<{ background: string }>`
  background: ${p => p.background};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`
