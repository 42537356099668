import React from 'react'
import { AxiosError } from 'axios'
import NotFound from './NotFound'
import UnknownError from './UnknownError'

type ErrorPageProps = {
  error?: any
}

const ErrorPage = ({ error }: ErrorPageProps) => {
  if (error instanceof AxiosError) {
    const code = error.request.status
    return <NotFound code={code} title={'Что-то пошло не так'} />
  }

  return <UnknownError />
}

export default ErrorPage
