import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 20px;
  gap: 4px;
  cursor: pointer;
`
export const SentimentItem = styled.div<{ background: string; width: number }>`
  width: ${p => p.width}%;
  background: ${p => p.background};
  height: 8px;
  border-radius: 8px;
`
