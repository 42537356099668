import React, { useCallback, memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import SingleArrow from 'Assets/SingleArrow'
import './index.css'
import { IItem, IItemCard } from 'Types/product'
import { ITopic } from 'Types/topic'
import useSelectedItemsStore from '../../../Store/useSelectedItemsStore'

export interface ColumnType<T> {
  title: string | React.ReactNode
  dataIndex?: string
  key?: string
  sorter?: (a: T, b: T) => number
  render?: (value: any, record: T, index: number) => React.ReactNode
  children?: ColumnType<T>[]
  editable?: boolean
}

interface CustomTableProps {
  tableData: any
  columns: ColumnType<any>[]
  isCheckboxesShow?: boolean
  width?: number
}

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 24px;
  width: 100%;
`

const StyledTable = styled(props => <Table {...props} />)`
  width: 100%;
  border-radius: 20px;
`

const ArrowWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const CustomTable = memo((props: CustomTableProps) => {
  const { selectedItems, setSelectedItems } = useSelectedItemsStore()
  const { tableData, columns, isCheckboxesShow, width } = props

  const processDataSourceWithKeys = useCallback((data: any[], level = 0): any[] => {
    return data.map(item => ({
      ...item,
      key: 'id-' + item.id + Math.random(),
      nestingLevel: level,
      children: item.children
        ? processDataSourceWithKeys(item.children, level + 1)
        : undefined,
    }))
  }, [])

  const dataSourceWithKeys = useMemo(
    () => processDataSourceWithKeys(tableData),
    [processDataSourceWithKeys, tableData],
  )

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IItemCard[]) => {
      setSelectedItems && setSelectedItems(selectedRows)
    },
    getCheckboxProps: (record: IItem) => ({
      disabled: record.category === '' || !record.children,
      style: {
        visibility: record.nestingLevel !== 1 ? 'hidden' : 'visible',
      },
    }),
    hideSelectAll: false,
  }

  return (
    <Wrapper>
      <StyledTable
        style={{ width: width || '100%' }}
        virtual
        rowSelection={
          isCheckboxesShow && {
            type: 'checkbox',
            ...rowSelection,
          }
        }
        className={'custom-table'}
        columns={columns}
        dataSource={dataSourceWithKeys}
        pagination={false}
        scroll={{ x: 1100 }}
        showSorterTooltip={false}
        expandable={{
          expandRowByClick: false,
          expandIcon: ({
            expanded,
            onExpand,
            record,
          }: {
            expanded: boolean
            onExpand: (record: IItem, e: any) => void
            record: IItem
          }) =>
            record.children && record.children.length ? (
              expanded ? (
                <ArrowWrapper onClick={e => onExpand(record, e)}>
                  <SingleArrow rotate={180} />
                </ArrowWrapper>
              ) : (
                <ArrowWrapper onClick={e => onExpand(record, e)}>
                  <SingleArrow />
                </ArrowWrapper>
              )
            ) : null,
        }}
      />
    </Wrapper>
  )
})

CustomTable.displayName = 'CustomTable'
export default CustomTable
