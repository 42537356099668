import React from 'react'
import { NoItemsWrapper } from './styled'
import Text from '../../../../Components/Kit/Text'
import Button from '../../../../Components/Kit/Button'
import { css, useTheme } from 'styled-components'
import Plus from '../../../../Assets/Plus'
import { useTranslation } from '../../../../Hooks/useTranslation'

type EmptyDataProps = {
  showAddItemModal: () => void
}
const EmptyData = ({ showAddItemModal }: EmptyDataProps) => {
  const t = useTranslation()
  const theme = useTheme()
  return (
    <NoItemsWrapper>
      <Text fontSize={14} fontWeight={400}>
        {t('Overview.noItems')}
      </Text>
      <Button
        onClick={showAddItemModal}
        buttonSize={'large'}
        fragment={css`
          min-width: 120px;
          max-width: 200px;
        `}
      >
        <Plus fill={theme.colors.icons.light} />
        {t('Overview.addItem')}
      </Button>
    </NoItemsWrapper>
  )
}

export default EmptyData
