import React from 'react'

const Account = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14.5272C15.7963 14.5272 19 15.1441 19 17.5241C19 19.905 15.7753 20.5 12 20.5C8.20458 20.5 5 19.8831 5 17.5031C5 15.1222 8.22471 14.5272 12 14.5272ZM12 3C14.5717 3 16.6322 5.05977 16.6322 7.62967C16.6322 10.1996 14.5717 12.2602 12 12.2602C9.42916 12.2602 7.36776 10.1996 7.36776 7.62967C7.36776 5.05977 9.42916 3 12 3Z"
        fill="black"
      />
    </svg>
  )
}

export default Account
