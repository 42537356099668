import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 9;
  right: 50%;
  transform: translateX(50%);
  height: 42px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 12px;
  background: rgba(35, 83, 255, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 100px;
  padding: 4px;
  box-sizing: border-box;
`
export const Selector = styled.div<{ left: number }>`
  transition: all 0.3s;
  position: absolute;
  top: 3px;
  height: 36px;
  width: 36px;
  z-index: 0;
  background: ${p => p.theme.colors.blocks?.blue};
  border-radius: 50%;
  left: ${p => p.left}px;
`
export const Button = styled.button`
  transition: all 0.3s;
  background: none;
  border: none;
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  width: 36px;
`
