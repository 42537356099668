import React from 'react'
import { IconType } from 'Types/icon'
import { useTheme } from 'styled-components'

const Delete = (props: IconType) => {
  const theme = useTheme()
  const { height = 14, width = 14, fill = theme.colors.icons.dark } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7928 4.7981C10.9257 4.7981 11.0463 4.8561 11.1416 4.9541C11.2304 5.05876 11.2752 5.18876 11.2622 5.32609C11.2622 5.37143 10.9069 9.86471 10.704 11.756C10.5769 12.9167 9.82867 13.6213 8.70634 13.6407C7.84336 13.66 6.99984 13.6667 6.16928 13.6667C5.2875 13.6667 4.42517 13.66 3.58812 13.6407C2.5034 13.6147 1.75454 12.8974 1.63394 11.756C1.42517 9.85805 1.07634 5.37143 1.06986 5.32609C1.06338 5.18876 1.10747 5.05876 1.19694 4.9541C1.28512 4.8561 1.4122 4.7981 1.54576 4.7981H10.7928ZM7.54323 0.333344C8.1326 0.333344 8.65908 0.744673 8.81144 1.33133L8.92037 1.818C9.00855 2.21466 9.35218 2.49532 9.74769 2.49532H11.6915C11.9508 2.49532 12.1667 2.71065 12.1667 2.98465V3.23798C12.1667 3.50531 11.9508 3.72731 11.6915 3.72731H0.64265C0.382655 3.72731 0.166748 3.50531 0.166748 3.23798V2.98465C0.166748 2.71065 0.382655 2.49532 0.64265 2.49532H2.58646C2.98131 2.49532 3.32495 2.21466 3.41378 1.81866L3.51557 1.364C3.67377 0.744673 4.19441 0.333344 4.79026 0.333344H7.54323Z"
        fill={fill}
      />
    </svg>
  )
}

export default Delete
