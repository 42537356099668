import styled from 'styled-components'
export const ReviewsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  overflow-y: auto;
  width: 100%;
`
export const NoDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
