import React from 'react'

const ThirdDecoration = () => {
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.750112 5.33327L9.97665 0L19.2209 5.33324L19.221 5.3332L28.4477 10.6665L19.221 15.9999L9.97672 10.6666L9.97665 10.6667V21.3332L0.75 15.9999V5.33333V5.3332L0.750112 5.33327ZM19.2214 26.6668L28.448 21.3335V10.6668L19.2214 16.0001V26.6668ZM19.221 16V26.6667L9.97665 32H9.97656L0.75 26.6667V16L9.97661 21.3333L19.221 16Z"
        fill="black"
      />
    </svg>
  )
}

export default ThirdDecoration
