import React from 'react'
import styled from 'styled-components'
import Parser from 'html-react-parser'

const StyledErrorWrap = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ECEFF9;
  width: 100vw;
  height: 100vh;
`

const StyledTitle = styled.span`
  transition: all 0.3s;
  padding: 0 20px;
  font-weight: 800;
  font-size: 64px;
  line-height: 76px;
  margin-bottom: 16px;
  font-family: 'Helvetica Neue', sans-serif;

  @media screen and (max-width: 500px) {
    font-weight: 800;
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 16px;
  }
`

const StyledSubTitle = styled.span`
  transition: all 0.3s;
  padding: 0 20px;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 56px;
  font-family: 'Helvetica Neue', sans-serif;

  @media screen and (max-width: 500px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 52px;
  }
`

type ErrorLayoutProps = {
  title?: string | number
  subtitle?: string
  children?: React.ReactNode
}

const ErrorLayout = ({ title, subtitle, children }: ErrorLayoutProps) => {
  return (
    <StyledErrorWrap>
      {Boolean(title) && <StyledTitle>{title}</StyledTitle>}
      {subtitle && <StyledSubTitle>{Parser(subtitle)}</StyledSubTitle>}
      {children}
    </StyledErrorWrap>
  )
}

export default ErrorLayout
