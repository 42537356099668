import React from 'react'
import {
  ProductCard,
  ProductText,
  ProductLinks,
  ProductInfo,
  Wrapper,
  ProductLink,
} from './styled'
import { css, useTheme } from 'styled-components'
import Text from 'Components/Kit/Text'
import AverageSentiment from 'Components/Charts/AvgSentiment'
import { ISimpleItem } from 'Types/product'
import { useNavigate } from 'react-router-dom'
import AverageRating from '../AverageRating'
import { IRating } from 'Types/rating'
import { getMarketplaceIcon } from 'Utils/marketplace'
import { ISentiment } from 'Types/sentiment'
import Tooltip from 'Components/Kit/Tooltip'
import ItemImage from 'Components/ItemImage'

type CommonInfoProps = {
  item: ISimpleItem
  rating: IRating
  sentiment: ISentiment
}

const CommonInfo = (props: CommonInfoProps) => {
  const { item, rating, sentiment } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const goToCategory = () => {
    navigate(`/group/${item.category_id}`)
  }
  const goToMarketplace = (link: string) => {
    window.open(link, '_blank')
  }

  return (
    <Wrapper>
      <ProductCard>
        <ProductInfo>
          <ItemImage link={item.icon_link} variant={'large'} />
          <ProductText>
            <Text
              fontSize={10}
              fontWeight={700}
              color={theme.colors.text.blue}
              onClick={goToCategory}
              styledFragment={css`cursor: pointer; text-transform: uppercase`}
            >
              {item.category}
            </Text>
            <Tooltip
              title={
                <Text fontSize={10} fontWeight={400}>
                  {item.name}
                </Text>
              }
              variant={'small'}
            >
              <Text
                fontSize={20}
                fontWeight={500}
                styledFragment={css`width: 180px; cursor: pointer`}
              >
                {item.name.length >= 30 ? `${item.name.slice(0, 30)}...` : item.name}
              </Text>
            </Tooltip>
          </ProductText>
        </ProductInfo>
        {Boolean(item.cards.length) && (
          <ProductLinks>
            {item.cards.map(card => (
              <ProductLink
                key={card.id}
                src={getMarketplaceIcon(card.marketplace)}
                onClick={() => goToMarketplace(card.link)}
              />
            ))}
          </ProductLinks>
        )}
      </ProductCard>
      <AverageRating rating={rating} />
      <AverageSentiment
        percentage={+(sentiment.score * 100).toFixed(0)}
        difference={sentiment.difference}
      />
    </Wrapper>
  )
}

export default CommonInfo
