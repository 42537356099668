import React from 'react'
import ErrorLayout from '../ErrorLayout'
import { StyledButtonsGrid } from '../ErrorLayout/styled'
import { ErrorButton } from '../styled'

const UnknownError = () => {
  return (
    <ErrorLayout subtitle={'Неизвестная ошибка'}>
      <StyledButtonsGrid>
        <ErrorButton onClick={() => window.location.replace('/overview')}>
          На главную
        </ErrorButton>
      </StyledButtonsGrid>
    </ErrorLayout>
  )
}

export default UnknownError
