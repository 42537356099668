import React, { useState } from 'react'
import {
  DeleteItemsWrapper,
  TableActionButton,
  TableActionButtonsWrapper,
  Wrapper,
} from './styled'
import Input from 'Components/Kit/Input'
import { css, useTheme } from 'styled-components'
import SearchIcon from 'Assets/Search'
import Text from 'Components/Kit/Text'
import Loader from 'Components/Kit/Loader'
import Delete from 'Assets/Delete'
import { useTranslation } from 'Hooks/useTranslation'
import useSelectedItemsStore from '../../../../Store/useSelectedItemsStore'

type ItemsTableActionsProps = {
  toggleAddToGroup: () => void
  handleComparisonClick: () => void
  deleteItems: () => void
  searchedItemQuery: string
  setSearchedItemQuery: (value: string) => void
  isDeleteLoading: boolean
}
const ItemsTableActions = ({
  toggleAddToGroup,
  handleComparisonClick,
  deleteItems,
  searchedItemQuery,
  setSearchedItemQuery,
  isDeleteLoading,
}: ItemsTableActionsProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const { selectedItems } = useSelectedItemsStore()

  return (
    <Wrapper>
      <Input
        fragment={css`
          padding-left: 32px;
          width: 230px
        `}
        leftIconSrc={<SearchIcon />}
        onChange={setSearchedItemQuery}
        value={searchedItemQuery}
        placeholder={t('Overview.search')}
        isValueValid
        inputSize={'small'}
      />
      {Boolean(selectedItems.length) && (
        <TableActionButtonsWrapper>
          <TableActionButton onClick={toggleAddToGroup}>
            <Text fontSize={13} fontWeight={700}>
              {t('Overview.addToGroup')}
            </Text>
          </TableActionButton>
          <TableActionButton onClick={handleComparisonClick}>
            <Text fontSize={13} fontWeight={700}>
              {t('Overview.compare')}
            </Text>
          </TableActionButton>
          <DeleteItemsWrapper onClick={deleteItems}>
            {isDeleteLoading ? (
              <Loader size={14} color={theme.colors.icons.light} />
            ) : (
              <Delete fill={theme.colors.icons.light} />
            )}
          </DeleteItemsWrapper>
        </TableActionButtonsWrapper>
      )}
    </Wrapper>
  )
}

export default ItemsTableActions
