import styled, { css } from 'styled-components'

export const NoImageItemWrapper = styled.div<{ variant: 'small' | 'large' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.lightGray};

  ${({ variant }) =>
    variant === 'small' &&
    css`
            width: 40px;
            height: 40px;
            border-radius: 12px;
          `};
`
export const Image = styled.img<{ variant: 'small' | 'large' }>`
  width: 180px;
  height: 180px;
  border-radius: 24px;
  object-fit: contain;

  ${({ variant }) =>
    variant === 'small' &&
    css`
            width: 40px;
            height: 40px;
            border-radius: 12px;
          `};
`
