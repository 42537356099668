import React from 'react'
import { IconType } from 'Types/icon'
const Plus = (props: IconType) => {
  const { fill } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 5L10 15M5 10H15" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export default Plus
