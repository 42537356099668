import React, { useEffect, useRef, useState } from 'react'
import { Wrapper, Selector, Button } from './styled'

type SwitcherProps = {
  firstSelectedIndex: number
  onChange: (buttonIndex: number) => void
  buttons: string[]
  width: number
  variant?: 'large' | 'small'
}

const Switcher = (props: SwitcherProps) => {
  const { firstSelectedIndex, onChange, buttons, width, variant = 'small' } = props

  const buttonWidthInPercent = 100 / buttons.length

  const [selectedButton, setSelectedButton] = useState<number>(firstSelectedIndex)
  const [selectorStyle, setSelectorStyle] = useState({ width: 0, left: 0 })
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([])
  const onButtonClick = (index: number) => {
    setSelectedButton(index)
    onChange(index)
  }

  useEffect(() => {
    const activeButton = buttonsRef.current[selectedButton]
    if (activeButton) {
      const { offsetWidth, offsetLeft } = activeButton
      setSelectorStyle({
        width: offsetWidth,
        left: offsetLeft,
      })
    }
  }, [selectedButton, width])

  return (
    <Wrapper width={width} variant={variant}>
      <Selector variant={variant} width={selectorStyle.width} left={selectorStyle.left} />
      {buttons.map((buttonTitle, index) => {
        return (
          <Button
            variant={variant}
            key={buttonTitle}
            type="button"
            value={index}
            onClick={() => onButtonClick(index)}
            width={buttonWidthInPercent}
            className={`${selectedButton === index && 'selected'}`}
            ref={el => (buttonsRef.current[index] = el)}
          >
            {buttonTitle}
          </Button>
        )
      })}
    </Wrapper>
  )
}

export default Switcher
