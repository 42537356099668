import React, { useState } from 'react'
import {
  ButtonWrapper,
  CategoriesWrapper,
  CategoryWrapper,
  LoaderWrapper,
  ScrollWrapper,
  SubCategoriesWrapper,
  SubCategoryWrapper,
  Wrapper,
} from './styled'
import Text from 'Components/Kit/Text'
import Button from 'Components/Kit/Button'
import { ActiveStep } from '../../types'
import { useFetch } from 'Hooks/useFetch'
import { TreesType, TreeType } from 'Types/trees'
import { useTheme } from 'styled-components'
import Loader from 'Components/Kit/Loader'
import { useTranslation } from 'Hooks/useTranslation'

type ChooseCategoryProps = {
  handleNextStep: (step: ActiveStep) => void
  treeType: TreeType
  setTreeType: (newTreeType: TreeType) => void
}
const ChooseCategory = ({
  handleNextStep,
  setTreeType,
  treeType,
}: ChooseCategoryProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const [activeCategory, setActiveCategory] = useState<number | null>(null)
  const [activeSubCategory, setActiveSubCategory] = useState<number | null>(null)

  const { data: trees } = useFetch<TreesType | null>('trees/types', null)
  const handleSelection = (key: 'place' | 'type', value: string, index: number) => {
    setTreeType({ ...treeType, [key]: value })
    if (key === 'place') {
      setActiveCategory(index)
      setActiveSubCategory(null)
    } else {
      setActiveSubCategory(index)
    }
  }
  const handleAddSources = () => {
    handleNextStep(ActiveStep.TOPICS)
  }

  const isButtonDisabled = !treeType.type

  return (
    <>
      <ScrollWrapper>
        {trees ? (
          <Wrapper>
            <Text fontSize={14} fontWeight={400}>
              {t('AddItem.chooseCategory')}
            </Text>
            <CategoriesWrapper>
              {trees?.places.map((place, index) => (
                <CategoryWrapper
                  key={index}
                  onClick={() => handleSelection('place', place, index)}
                  isActive={index === activeCategory}
                >
                  <Text fontSize={14} fontWeight={400}>
                    {place}
                  </Text>
                </CategoryWrapper>
              ))}
            </CategoriesWrapper>
            <Text fontSize={14} fontWeight={400}>
              {t('AddItem.chooseSubcategory')}
            </Text>
            <SubCategoriesWrapper>
              {trees?.types.map((type, index) => (
                <SubCategoryWrapper
                  key={index}
                  onClick={() => handleSelection('type', type, index)}
                  isActive={index === activeSubCategory}
                >
                  <Text fontSize={14} fontWeight={400}>
                    {type}
                  </Text>
                </SubCategoryWrapper>
              ))}
            </SubCategoriesWrapper>
          </Wrapper>
        ) : (
          <LoaderWrapper>
            <Loader size={25} color={theme.colors.blocks.dark} />
          </LoaderWrapper>
        )}
      </ScrollWrapper>
      <ButtonWrapper>
        <Button
          disabled={isButtonDisabled}
          onClick={handleAddSources}
          buttonSize={'large'}
        >
          {t('AddItem.addSource')}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default ChooseCategory
