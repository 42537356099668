import { useRef, useState } from 'react'
import instance from '../API/instance'

type HttpMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export function useRequest<T, P extends Record<string, any> = object>() {
  const [data, setData] = useState<T>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const abortController = useRef(new AbortController())

  const requestData = async (method: HttpMethod, url: string, params?: P) => {
    setIsLoading(true)
    abortController.current.abort()
    abortController.current = new AbortController()

    try {
      const response = await instance.request({
        method,
        url,
        data: params,
        signal: abortController.current.signal,
      })

      if (response.data) setData(response.data)
    } catch (error) {
      let message = 'Unknown Error'
      if (error instanceof Error) message = error.message
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }

  return { data, isLoading, requestData, error }
}
