import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  background: ${p => p.theme.colors.blocks.light};
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
`
