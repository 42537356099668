export default {
  'AddItem.title': 'Добавить продукт',
  'AddItem.chooseCategory': 'Выберите источник где размещен ваш товар',
  'AddItem.chooseSubcategory': 'Выберите категорию вашего продукта',
  'AddItem.addSource': 'Добавить источник',
  'AddItem.addLinksDescription':
    'Предоставьте ссылки на все источники, где размещен ваш продукт.',
  'AddItem.specifyYourRequestDescription':
    'Укажите дату начала аналитики или количество отзывов. Вы также можете добавить оба параметра.',
  'AddItem.source': 'Источник',
  'AddItem.addLinkPlaceholder': 'Ссылка',
  'AddItem.fieldRequired': 'Обязательное поле',
  'AddItem.incorrectLink': 'Мы не поддерживаем данный источник',
  'AddItem.specifyYourRequestDate': 'Дата начала',
  'AddItem.specifyYourRequestCount': 'Кол-во отзывов',
  'AddItem.specifyYourRequestLimitError': 'Превышает оставшийся лимит',
  'AddItem.addLinks': 'Добавить ссылки',
  'AddItem.back': 'Назад',
  'AddItem.addTopics': 'Добавить темы',
  'AddItem.editTree':
    'Отредактируйте список тем по которому мы будем анализировать отзывы.',
  'AddItem.addTopicPlaceholder': 'Тема',
  'AddItem.addTopic': 'Добавить тему',
}
