import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Selector, Wrapper } from './styled'
import GroupNav from 'Assets/GroupNav'
import ItemNav from 'Assets/ItemNav'
import OverviewNav from 'Assets/OverviewNav'
import Compare from 'Assets/Compare'
import { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useIdsStore } from 'Store/useIdsStore'
import ReviewsNav from 'Assets/ReviewsNav'

const Navigation = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { itemId, groupId } = useIdsStore()

  const [selectedButton, setSelectedButton] = useState<number>(0)
  const [selectorStyle, setSelectorStyle] = useState({ left: 0 })
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([])

  const buttons = useMemo(
    () => [
      { id: 0, path: '/overview', component: OverviewNav, enabled: true },
      {
        id: 1,
        path: groupId ? `/group/${groupId}` : '#',
        component: GroupNav,
        enabled: Boolean(groupId),
      },
      {
        id: 2,
        path: itemId ? `/products/${itemId}` : '#',
        component: ItemNav,
        enabled: Boolean(itemId),
      },
      { id: 3, path: '/reviews', component: ReviewsNav, enabled: true },
      { id: 4, path: '/comparison', component: Compare, enabled: true },
    ],
    [groupId, itemId],
  )

  const handleClick = useCallback(
    (index: number, path: string) => {
      if (path === '#') return
      setSelectedButton(index)
      navigate(path, { replace: true })
    },
    [navigate],
  )
  const getButtonFill = (index: number, condition = true) => {
    if (!condition) return theme.colors.icons.gray
    return selectedButton === index ? theme.colors.icons.light : theme.colors.icons.dark
  }

  useEffect(() => {
    const path = window.location.pathname
    if (path.includes('/group')) setSelectedButton(1)
    else if (path.includes('/products')) setSelectedButton(2)
    else if (path.includes('/reviews')) setSelectedButton(3)
    else if (path.includes('/comparison')) setSelectedButton(4)
    else setSelectedButton(0)
  }, [window.location.pathname])

  useEffect(() => {
    const activeButton = buttonsRef.current[selectedButton]
    if (activeButton) {
      const { offsetLeft } = activeButton
      setSelectorStyle({
        left: offsetLeft,
      })
    }
  }, [selectedButton])

  return (
    <Wrapper>
      <Selector left={selectorStyle.left} />
      {buttons.map(({ id, path, component: IconComponent, enabled }) => (
        <Button
          key={id}
          type="button"
          onClick={() => handleClick(id, path)}
          ref={el => (buttonsRef.current[id] = el)}
        >
          <IconComponent fill={getButtonFill(id, enabled)} />
        </Button>
      ))}
    </Wrapper>
  )
}

export default Navigation
