import styled from 'styled-components'
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: ${p => p.theme.colors.blocks?.light};
  width: 232px;
  min-width: 232px;
  height: 88px;
  padding: 16px 20px 16px 20px;
  border-radius: 24px;
  cursor: pointer;
`
export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
