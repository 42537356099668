import React from 'react'

const Info = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C4.13449 15 1 11.8663 1 8C1 4.13449 4.13449 1 8 1C11.8663 1 15 4.13449 15 8C15 11.8663 11.8663 15 8 15Z"
        stroke="#D1D3E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00369 10.8726L8.00369 7.52848"
        stroke="#D1D3E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00369 5.12741L7.99612 5.12741"
        stroke="#D1D3E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Info
