import { create } from 'zustand'

type FilterStore = {
  activePeriod: { startDate: string; endDate: string }
  setActivePeriod: (startDate: string, endDate: string) => void
  activePeriodType: number
  setActivePeriodType: (value: number) => void
}

export const useFiltersStore = create<FilterStore>(set => ({
  activePeriod: {
    startDate: '',
    endDate: '',
  },
  setActivePeriod: (startDate, endDate) => set({ activePeriod: { startDate, endDate } }),
  activePeriodType: 2,
  setActivePeriodType: value => set({ activePeriodType: value }),
}))
