import React from 'react'
import { AddCategoryButton, Wrapper, ScrollWrapper } from './styled'
import Plus from 'Assets/Plus'
import GroupCard from './GroupCard'
import { useTheme } from 'styled-components'
import { IGroup } from 'Types/category'

type GroupsProps = {
  groups: IGroup[]
  toggleCreateGroup: () => void
}

const Groups = ({ groups, toggleCreateGroup }: GroupsProps) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <AddCategoryButton onClick={toggleCreateGroup}>
        <Plus fill={theme.colors.icons.light} />
      </AddCategoryButton>
      <ScrollWrapper>
        {groups.map(group => (
          <GroupCard
            key={group.id}
            name={group.name}
            id={group.id}
            productsCount={group.products.length}
            difference={group.difference}
          />
        ))}
      </ScrollWrapper>
    </Wrapper>
  )
}

export default Groups
