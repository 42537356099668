import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 107px);
  background: ${p => p.theme.colors.background};
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 32px;

  @media screen and (min-width: 1470px) {
    max-width: 1360px;
  }
`
