import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: center;
  height: 100%;
  background: ${p => p.theme.colors.background};
  padding-bottom: 40px;
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 60px;

  @media screen and (min-width: 1470px) {
    max-width: 1360px;
  }
`
