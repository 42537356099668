import React from 'react'
import { RouterProvider } from 'react-router-dom'
import CoolErrorBoundary from './CoolErrorBoundary'
import { router } from './Router'

function App() {
  return (
    <CoolErrorBoundary>
      <RouterProvider router={router} />
    </CoolErrorBoundary>
  )
}

export default App
