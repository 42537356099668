import React, { useEffect, useState } from 'react'
import {
  AddInputWrapper,
  ButtonsWrapper,
  CrossIcon,
  InputsAndAdd,
  InputsWrapper,
  InputTitleWrapper,
  InputWrapper,
  ScrollWrapper,
  SpecifyInputsWrapper,
  Texts,
} from './styled'
import { useTranslation } from 'Hooks/useTranslation'
import Text from 'Components/Kit/Text'
import Input from 'Components/Kit/Input'
import { useFetch } from 'Hooks/useFetch'
import { IMarketplace } from 'Types/marketplace'
import { css, useTheme } from 'styled-components'
import Plus from 'Assets/Plus'
import Button from 'Components/Kit/Button'
import BetweenDatePicker from 'Components/Kit/BetweenDatePicker'
import Cross from 'Assets/Cross'
import { ActiveStep, NewItem } from '../../types'

type AddSourcesProps = {
  handleActiveStep: (step: ActiveStep) => void
  setItems: (links: NewItem[]) => void
  items: NewItem[]
}

const AddSources = ({ handleActiveStep, setItems, items }: AddSourcesProps) => {
  const t = useTranslation()
  const theme = useTheme()

  const [marketplacesRegexp, setMarketplacesRegexp] = useState<RegExp[]>([])

  const { data: marketplaces } = useFetch<IMarketplace[]>('marketplaces/', [])

  useEffect(() => {
    const getMarketplacesRegExp = () => {
      const regexpArray: RegExp[] = []
      if (marketplaces.length) {
        marketplaces.forEach(marketplace => {
          const regexpString = JSON.parse(marketplace['product_regex'])
          const regexp = new RegExp(regexpString)
          regexpArray.push(regexp)
        })
        setMarketplacesRegexp(regexpArray)
      }
    }
    getMarketplacesRegExp()
  }, [marketplaces])

  const checkLinkValidity = (link: string): boolean => {
    return marketplacesRegexp.some(regex => regex.test(link))
  }

  const handleInputChange = (index: number, field: string, value: any) => {
    const newItems = [...items]
    newItems[index] = { ...newItems[index], [field]: value }
    setItems(newItems)
  }

  const handleCrossClick = (index: number) => {
    const newItems = items.filter((_, i) => i !== index)
    setItems(newItems)
  }

  const handleAddLink = () => {
    setItems([...items, { link: '', parse_review_count: 100, since_date: null }])
  }

  const handleAddItem = async () => {
    handleActiveStep(ActiveStep.TOPICS)
  }

  const isLinksValid = items.every(link => checkLinkValidity(link.link))

  return (
    <>
      <ScrollWrapper>
        <Texts>
          <Text fontSize={14} fontWeight={400}>
            {t('AddItem.addLinksDescription')}
          </Text>
          <Text fontSize={14} fontWeight={400}>
            {t('AddItem.specifyYourRequestDescription')}
          </Text>
        </Texts>
        <InputsAndAdd>
          <InputsWrapper>
            {items.map((link, index) => (
              <InputWrapper key={index}>
                <InputTitleWrapper>
                  <Text fontSize={16} fontWeight={500}>
                    {t('AddItem.source')} {index + 1}
                  </Text>
                  {index > 0 && (
                    <CrossIcon onClick={() => handleCrossClick(index)}>
                      <Cross fill={theme.colors.icons.dark} rotate={45} />
                    </CrossIcon>
                  )}
                </InputTitleWrapper>
                <Input
                  onChange={e => handleInputChange(index, 'link', e)}
                  value={link.link}
                  placeholder={t('AddItem.addLinkPlaceholder')}
                  isValueValid={link.link === '' ? true : checkLinkValidity(link.link)}
                  invalidMessage={
                    link.link === ''
                      ? t('AddItem.fieldRequired')
                      : t('AddItem.incorrectLink')
                  }
                  inputSize={'large'}
                  fragment={css`background: ${theme.colors.blocks?.lightGray};`}
                />
                <SpecifyInputsWrapper>
                  <BetweenDatePicker
                    placeholder={t('AddItem.specifyYourRequestDate')}
                    value={link.since_date}
                    onChange={date => handleInputChange(index, 'since_date', date)}
                    mode={'date'}
                  />
                  <Input
                    onChange={e => handleInputChange(index, 'parse_review_count', e)}
                    value={String(link.parse_review_count ?? '')}
                    placeholder={t('AddItem.specifyYourRequestCount')}
                    isValueValid
                    invalidMessage={t('AddItem.specifyYourRequestLimitError')}
                    inputSize={'large'}
                    fragment={css`background: ${theme.colors.blocks?.lightGray};`}
                  />
                </SpecifyInputsWrapper>
              </InputWrapper>
            ))}
          </InputsWrapper>
          <AddInputWrapper onClick={handleAddLink}>
            <Plus fill={theme.colors.icons.blue} />
            <Text fontSize={14} fontWeight={700} color={theme.colors.text.blue}>
              {t('AddItem.addLinks')}
            </Text>
          </AddInputWrapper>
        </InputsAndAdd>
      </ScrollWrapper>
      <ButtonsWrapper>
        <Button
          disabled={false}
          fragment={css`width: 160px;`}
          onClick={() => handleActiveStep(ActiveStep.CATEGORIES)}
          buttonSize={'large'}
          buttonType={'secondary'}
        >
          {t('AddItem.back')}
        </Button>
        <Button
          disabled={!isLinksValid}
          fragment={css`width: 160px;`}
          onClick={handleAddItem}
          buttonSize={'large'}
        >
          {t('AddItem.addTopics')}
        </Button>
      </ButtonsWrapper>
    </>
  )
}

export default AddSources
