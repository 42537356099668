import axios from 'axios'

const axiosConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
}

const request = axios.create({
  baseURL: axiosConfig.baseUrl,
})

request.interceptors.request.use(config => {
  const value = window.localStorage.getItem('user-storage')
  let token = ''
  if (value) {
    const parsedValue = JSON.parse(value)
    token = parsedValue.state.user ? parsedValue.state.user : ''
  }
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  return config
})

request.interceptors.response.use(
  r => r,
  // e => {
  //   // if (axios.isCancel(e)) return
  //   throw e
  // },
)

export default request
