import React, { useState } from 'react'
import {
  TopicsAndSearchWrapper,
  TopicSearchWrapper,
  TopicsWrapper,
  TopicWrapper,
} from './styled'
import Input from '../Kit/Input'
import { css } from 'styled-components'
import SearchIcon from 'Assets/Search'
import { Checkbox } from 'antd'
import Text from '../Kit/Text'
import { useTranslation } from 'Hooks/useTranslation'

type SearchTopicsProps = {
  elems: { id: string; name: string }[]
  handleChange: (topics: { id: string; name: string }[]) => void
  activeElem: { id: string; name: string }[]
  maxSelectableElems: number
}
const SimpleSearch = ({
  elems,
  handleChange,
  activeElem,
  maxSelectableElems,
}: SearchTopicsProps) => {
  const t = useTranslation()
  const [searchedTopicQuery, setSearchedTopicQuery] = useState('')

  const filteredItems = elems
    ? elems.filter(elem =>
        elem.name.toLowerCase().startsWith(searchedTopicQuery.toLowerCase()),
      )
    : []
  const isActiveTopic = (elemId: string) => {
    return activeElem.some(elem => elem.id === elemId)
  }
  const handleCheckboxChange = (currentElem: { id: string; name: string }) => {
    if (isActiveTopic(currentElem.id)) {
      handleChange(activeElem.filter(elem => elem.id !== currentElem.id))
    } else if (activeElem.length < maxSelectableElems) {
      handleChange([...activeElem, currentElem])
    }
  }

  return (
    <TopicsAndSearchWrapper>
      <TopicSearchWrapper>
        <Input
          fragment={css`
            padding-left: 32px;
          `}
          leftIconSrc={<SearchIcon />}
          onChange={e => setSearchedTopicQuery(e)}
          value={searchedTopicQuery}
          placeholder={t('Reviews.search')}
          isValueValid
          inputSize={'small'}
        />
      </TopicSearchWrapper>
      <TopicsWrapper>
        {filteredItems.map(item => (
          <TopicWrapper key={item.id}>
            <Checkbox
              checked={isActiveTopic(item.id.toString())}
              onChange={() =>
                handleCheckboxChange({ id: item.id.toString(), name: item.name })
              }
              disabled={
                !isActiveTopic(item.id.toString()) &&
                activeElem.length >= maxSelectableElems
              }
            />
            <Text
              fontSize={14}
              fontWeight={400}
              styledFragment={css`
                :first-letter {
                  text-transform: uppercase;
                }
              `}
            >
              {item.name.length > 33 ? `${item.name.slice(0, 33)}...` : item.name}
            </Text>
          </TopicWrapper>
        ))}
      </TopicsWrapper>
    </TopicsAndSearchWrapper>
  )
}

export default SimpleSearch
