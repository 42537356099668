import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const CustomSelect = styled.div<{
  variant: 'large' | 'small'
  isDisabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${p => (p.variant === 'large' ? 160 : 200)}px;
  height: ${p => (p.variant === 'large' ? 45 : 30)}px;
  padding: ${p => (p.variant === 'large' ? '8px 16px' : '4px 12px')};
  border-radius: ${p => (p.variant === 'large' ? 16 : 10)}px;
  background: ${p =>
    p.variant === 'large' ? p.theme.colors.blocks?.light : p.theme.colors.blocks?.gray};
  cursor: pointer;
  pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};
  opacity: ${p => (p.isDisabled ? 0.3 : 1)};
`
export const SelectItems = styled.div<{ isActive: boolean; variant: 'large' | 'small' }>`
  position: absolute;
  z-index: 100;
  top: ${p => (p.variant === 'large' ? 53 : 38)}px;
  display: ${p => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  padding: ${p => (p.variant === 'large' ? 8 : 4)}px;
  width: ${p => (p.variant === 'large' ? 160 : 200)}px;
  background: ${p => p.theme.colors.blocks?.light};
  border-radius: 16px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  max-height: 180px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
`
export const SelectItem = styled.div<{ variant: 'large' | 'small' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${p => p.theme.colors.blocks?.light};
  cursor: pointer;
  padding: ${p => (p.variant === 'large' ? '12px' : '6px 8px')};
  min-height: 36px;
  width: ${p => (p.variant === 'large' ? 144 : 192)}px;
  border-radius: 12px;

  &:hover {
    background: ${p => p.theme.colors.blocks?.lightGray};
  }
`
