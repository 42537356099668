import { IItemCard } from '../Types/product'
import { create } from 'zustand'

type SelectedItemsStore = {
  selectedItems: IItemCard[]
  setSelectedItems: (items: IItemCard[]) => void
  addItem: (item: IItemCard) => void
  removeItem: (id: number) => void
}

const useSelectedItemsStore = create<SelectedItemsStore>(set => ({
  selectedItems: [],
  setSelectedItems: items => set({ selectedItems: items }),
  addItem: item => set(state => ({ selectedItems: [...state.selectedItems, item] })),
  removeItem: id =>
    set(state => ({
      selectedItems: state.selectedItems.filter(item => item.id !== id),
    })),
}))

export default useSelectedItemsStore
