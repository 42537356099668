import React from 'react'
import { Wrapper, TextWrapper, ButtonsWrapper } from './styled'
import Text from 'Components/Kit/Text'
import Button from 'Components/Kit/Button'
import { css, useTheme } from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'
import GenericModal from 'Components/Kit/GenericModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

const DeleteItems = NiceModal.create(() => {
  const t = useTranslation()
  const theme = useTheme()
  const modal = useModal()
  const handleDeleteItems = () => {
    modal.resolve()
    modal.remove()
  }

  return (
    <GenericModal title={t('Overview.deleteItems')} onClose={() => modal.remove()}>
      <Wrapper>
        <TextWrapper>
          <Text fontSize={15} fontWeight={400}>
            {t('Overview.deleteItemsQuestion')}
          </Text>
        </TextWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => modal.remove()}
            buttonSize={'large'}
            fragment={css`
              width: 100%;
              background: ${theme.colors.blocks?.gray};
              color: ${theme.colors.text.main};

              &:hover {
                background: ${theme.colors.blocks?.gray};
              }
            `}
          >
            {t('Overview.close')}
          </Button>
          <Button
            onClick={handleDeleteItems}
            buttonSize={'large'}
            fragment={css`width: 100%`}
          >
            {t('Overview.delete')}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </GenericModal>
  )
})

export default DeleteItems
