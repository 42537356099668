import React from 'react'
import {
  ItemsListWrapper,
  OtherItemsWrapper,
  ProductListItem,
  RatingAndSentimentWrapper,
  RatingsListWrapper,
  SentimentsListWrapper,
  TableIcon,
} from './styled'
import Text from 'Components/Kit/Text'
import Tooltip from 'Components/Kit/Tooltip'
import { useTheme } from 'styled-components'
import { sliceText } from 'Utils/text'
import Star from 'Assets/Star'
import SingleArrow from 'Assets/SingleArrow'
import NoData from 'Components/Kit/NoData'
import { ISimpleItem } from 'Types/product'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'Hooks/useTranslation'
import ItemImage from 'Components/ItemImage'

type OtherItemsProps = {
  items: ISimpleItem[]
}

const OtherItems = ({ items }: OtherItemsProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const t = useTranslation()
  const goToProduct = (id: number) => {
    navigate(`/products/${id}`)
  }

  return (
    <OtherItemsWrapper>
      <ItemsListWrapper>
        <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
          {t('TopItems.products').toUpperCase()}
        </Text>
        {items.slice(1, 6).map(item => (
          <ProductListItem key={item.id} onClick={() => goToProduct(item.id)}>
            <ItemImage link={item.icon_link} />
            <Tooltip
              title={
                <Text fontSize={10} fontWeight={400}>
                  {item.name}
                </Text>
              }
              variant={'small'}
            >
              <Text fontSize={14} fontWeight={400}>
                {sliceText(20, item.name)}
              </Text>
            </Tooltip>
          </ProductListItem>
        ))}
      </ItemsListWrapper>
      <RatingAndSentimentWrapper>
        <RatingsListWrapper>
          <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
            {t('TopItems.avgRating').toUpperCase()}
          </Text>
          {items.slice(1, 6).map(item => (
            <TableIcon key={item.id}>
              {item.avg_rating ? (
                <>
                  <Star width={16} height={16} viewBox={'0 0 20 20'} />
                  <Text fontSize={14} fontWeight={400}>
                    {item.avg_rating?.toFixed(1)}
                  </Text>
                </>
              ) : (
                <NoData />
              )}
            </TableIcon>
          ))}
        </RatingsListWrapper>
        <SentimentsListWrapper>
          <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
            {t('TopItems.sentiment').toUpperCase()}
          </Text>
          {items.slice(1, 6).map(item => (
            <TableIcon key={item.id}>
              {item.difference !== 0 ? (
                <>
                  <Text fontSize={14} fontWeight={400}>
                    {(Math.abs(item.difference) * 100)?.toFixed(0)}%
                  </Text>
                  <SingleArrow
                    fill={
                      item.difference >= 0
                        ? theme.colors.icons.green
                        : theme.colors.icons.red
                    }
                    rotate={item.difference >= 0 ? 180 : 0}
                  />
                </>
              ) : (
                <NoData />
              )}
            </TableIcon>
          ))}
        </SentimentsListWrapper>
      </RatingAndSentimentWrapper>
    </OtherItemsWrapper>
  )
}

export default OtherItems
