import { useFetch } from 'Hooks/useFetch'
import { ISimpleItem, ItemResponseType } from 'Types/product'
import { GroupResponseType, IGroup } from 'Types/category'
import { ISimpleTopic } from 'Types/topic'
import { IRating } from 'Types/rating'
import { ISentiment } from 'Types/sentiment'
import { IMarketplace } from 'Types/marketplace'
import { SentimentResponseType } from 'Types/charts'
import { NpsType } from '../../../Types/nps'

type UseOverviewDataProps = {
  marketplace: string
  date__gte: string
  date__lte: string
  topProductsType: number
  discussedTopicsType: number
}
const useOverviewData = (props: UseOverviewDataProps) => {
  const { marketplace, discussedTopicsType, topProductsType, date__lte, date__gte } =
    props

  const requiredRequestParams = {
    marketplace,
    date__gte,
    date__lte,
  }

  const {
    data: items,
    isLoading: isItemsLoading,
    fetchData: fetchItems,
  } = useFetch<ItemResponseType | null>('products/', null, {
    ...requiredRequestParams,
  })

  const { data: topItems, isLoading: isTopItemsLoading } = useFetch<ISimpleItem[]>(
    'products/top/',
    [],
    {
      ...requiredRequestParams,
      sort: topProductsType === 0 ? 'positive' : 'negative',
    },
  )

  const { data: discussedTopics, isLoading: isDiscussedTopicsLoading } = useFetch<
    ISimpleTopic[]
  >('topics/top/', [], {
    ...requiredRequestParams,
    sentiment: discussedTopicsType === 0 ? 'positive' : 'negative',
  })

  const { data: lineChartData, isLoading: isLineChartLoading } =
    useFetch<SentimentResponseType | null>(
      'plots/review_sentiment_over_time_plot',
      null,
      {
        ...requiredRequestParams,
      },
    )

  const {
    data: groups,
    isLoading: isGroupsLoading,
    fetchData: fetchGroups,
  } = useFetch<GroupResponseType | null>('categories/', null, {
    ...requiredRequestParams,
  })

  const { data: rating, isLoading: isRatingLoading } = useFetch<IRating | null>(
    'products/rating/',
    null,
    {
      ...requiredRequestParams,
    },
  )

  const { data: sentiment, isLoading: isSentimentLoading } = useFetch<ISentiment>(
    'products/sentiment/',
    { score: 0, difference: 0 },
    {
      ...requiredRequestParams,
    },
  )

  const { data: nps, isLoading: isNpsLoading } = useFetch<NpsType | null>(
    'products/nps/',
    null,
    {
      ...requiredRequestParams,
    },
  )

  const { data: marketplaces } = useFetch<IMarketplace[]>('marketplaces/me/', [])

  return {
    items,
    isItemsLoading,
    fetchItems,
    topItems,
    isTopItemsLoading,
    topProductsType,
    discussedTopics,
    isDiscussedTopicsLoading,
    discussedTopicsType,
    lineChartData,
    nps,
    isLineChartLoading,
    groups,
    isGroupsLoading,
    fetchGroups,
    rating,
    isRatingLoading,
    sentiment,
    isSentimentLoading,
    marketplaces,
    isNpsLoading,
  }
}

export default useOverviewData
