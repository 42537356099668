import React, { ReactNode } from 'react'
import { Wrapper } from './styled'
import { useTheme } from 'styled-components'
import SingleArrow from 'Assets/SingleArrow'
import Text from 'Components/Kit/Text'

type TagProps = {
  content: string | ReactNode
  withIcon: boolean
  view: 'red' | 'green' | 'gray' | 'blue'
}

const Tag = ({ content, withIcon, view }: TagProps) => {
  const theme = useTheme()
  return (
    <Wrapper background={theme.colors.tags[view].background}>
      {withIcon && (
        <SingleArrow
          width={6}
          height={6}
          rotate={view === 'red' ? 0 : 180}
          fill={theme.colors.tags[view].text}
        />
      )}
      <Text fontSize={10} fontWeight={700} color={theme.colors.tags[view].text}>
        {content}
      </Text>
    </Wrapper>
  )
}

export default Tag
