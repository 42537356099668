import React from 'react'
import MockItemImage from 'Assets/MockItemImage'
import { Image, NoImageItemWrapper } from './styled'

type ItemImageProps = {
  link: string
  variant?: 'small' | 'large'
}

const ItemImage = (props: ItemImageProps) => {
  const { variant = 'small', link } = props
  const size = variant === 'small' ? 19 : 84

  return link ? (
    <Image src={link} variant={variant} />
  ) : (
    <NoImageItemWrapper variant={variant}>
      <MockItemImage width={size} height={size} />
    </NoImageItemWrapper>
  )
}

export default ItemImage
