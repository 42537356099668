import React from 'react'

const Search = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75561 12.3439C9.84212 12.3439 12.3442 9.84176 12.3442 6.75525C12.3442 3.66874 9.84212 1.16663 6.75561 1.16663C3.6691 1.16663 1.16699 3.66874 1.16699 6.75525C1.16699 9.84176 3.6691 12.3439 6.75561 12.3439Z"
        stroke="#D1D3E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6426 10.9325L12.8336 13.1179"
        stroke="#D1D3E0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Search
