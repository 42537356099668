import styled from 'styled-components'
export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${p => p.theme.colors.blur.main};
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
`
export const Wrapper = styled.div`
  padding: 28px;
  border-radius: 32px;
  background: ${p => p.theme.colors.blocks?.light};
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
`
export const CrossIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
`
