import React from 'react'
import { SentimentItem, SentimentType, Wrapper } from './styled'
import { ReviewsSentiment } from 'Types/product'
import Text from 'Components/Kit/Text'
import { useTheme } from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'

type SentimentChartTooltipProps = {
  sentiments: ReviewsSentiment
  type?: 'dark' | 'light'
}

const SentimentChartTooltip = ({
  sentiments,
  type = 'light',
}: SentimentChartTooltipProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const isLightTheme = type === 'light'
  return (
    <Wrapper>
      {sentiments.p >= 0 && (
        <SentimentItem>
          <SentimentType background={theme.colors.charts.green} />
          <Text
            fontSize={12}
            fontWeight={400}
            color={isLightTheme ? theme.colors.text.light : theme.colors.text.main}
          >
            {t('SentimentChartTooltip.positive')}: <strong>{sentiments.p}</strong>
          </Text>
        </SentimentItem>
      )}
      {sentiments.n >= 0 && (
        <SentimentItem>
          <SentimentType background={theme.colors.charts.red} />
          <Text
            fontSize={12}
            fontWeight={400}
            color={isLightTheme ? theme.colors.text.light : theme.colors.text.main}
          >
            {t('SentimentChartTooltip.negative')}: <strong>{sentiments.n}</strong>
          </Text>
        </SentimentItem>
      )}
      {sentiments.i >= 0 && (
        <SentimentItem>
          <SentimentType background={theme.colors.charts.blue} />
          <Text
            fontSize={12}
            fontWeight={400}
            color={isLightTheme ? theme.colors.text.light : theme.colors.text.main}
          >
            {t('SentimentChartTooltip.neutral')}: <strong>{sentiments.i}</strong>
          </Text>
        </SentimentItem>
      )}
    </Wrapper>
  )
}

export default SentimentChartTooltip
