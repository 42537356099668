import styled from 'styled-components'
export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 600px;
  height: 600px;
`
export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-top: 28px;
  padding-bottom: 28px;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`
export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const ItemNameAndImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`
export const AddItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 12px;
  background: ${p => p.theme.colors.blocks.lightGray};
  cursor: pointer;
`
export const DeleteIcon = styled.div`
  width: 13px;
  height: 13px;
  cursor: pointer;
`
export const ChoseGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`
export const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  margin-bottom: 24px;
`
export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  border-radius: 16px;
  background: ${p => p.theme.colors.blocks?.lightGray};
  cursor: pointer;
`
export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 21%);
  width: 100%;
`
