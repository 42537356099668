import React from 'react'

const SecondDecoration = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.75 8C24.75 3.59111 21.1589 0 16.75 0C12.3411 0 8.75 3.59111 8.75 8C4.34111 8 0.75 11.5911 0.75 16C0.75 20.4267 4.34111 24 8.75 24C8.75 28.4267 12.3411 32 16.75 32C21.1577 32 24.748 28.4287 24.75 24.0037H24.7539V24C29.161 23.9979 32.75 20.4254 32.75 16C32.75 11.5911 29.1589 8 24.75 8ZM16.7539 16.0037L24.75 16.0037V16V8H16.75H8.75V16V24H16.75H16.7539V16.0037ZM16.7539 16.0037V8.00371H8.75391V16.0037H16.7539Z"
        fill="black"
      />
    </svg>
  )
}

export default SecondDecoration
