import React, { useEffect, useState } from 'react'

declare global {
  interface Window {
    YaSendSuggestToken?: any
  }
}
const YandexOAuthToken = () => {
  const [isSdkLoaded, setIsSdkLoaded] = useState(false)

  useEffect(() => {
    const script1 = document.createElement('script')
    script1.src =
      'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js'
    script1.addEventListener('load', () => setIsSdkLoaded(true))
    document.body.appendChild(script1)
  }, [])

  useEffect(() => {
    if (window.YaSendSuggestToken && isSdkLoaded) {
      window.YaSendSuggestToken(`https://dev.reviews.napoleonit.ru/start/`, {
        flag: true,
      })
    }
  }, [isSdkLoaded])

  return <div></div>
}

export default YandexOAuthToken
