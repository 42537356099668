export default {
  'TopicTable.topic': 'Topic',
  'TopicTable.source': 'Source',
  'TopicTable.totalReviews': 'Total reviews',
  'TopicTable.positive': 'Positive',
  'TopicTable.negative': 'Negative',
  'TopicTable.neutral': 'Neutral',
  'TopicTable.toneOfReviews': 'Tone of reviews',
  'TopicTable.multiple': 'Multiple',
}
