import { useEffect, useRef, useState } from 'react'
import instance from 'API/instance'
import { IParams } from 'Types/requestParams'
import axios from 'axios'

export function useFetch<T>(
  fetchUrl: string,
  initialState: T,
  params?: IParams,
  skipInitialFetch?: boolean,
) {
  const [data, setData] = useState<T>(initialState)
  const [isLoading, setIsLoading] = useState(true)
  const abortController = useRef(new AbortController())

  const fetchData = async () => {
    setIsLoading(true)
    abortController.current.abort()
    abortController.current = new AbortController()

    try {
      let requestParams: IParams = {}

      if (params) {
        requestParams = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value !== ''),
        )
      }

      const response = await instance.get(fetchUrl, {
        params: requestParams,
        signal: abortController.current.signal,
      })
      setData(response.data)
    } catch (err) {
      if (!axios.isCancel(err)) throw err
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!skipInitialFetch) {
      void fetchData()
    }
    return () => abortController.current.abort()
  }, [fetchUrl, JSON.stringify(params), skipInitialFetch])

  return { data, isLoading, fetchData }
}
