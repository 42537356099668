import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
`
export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
  padding-bottom: 80px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
export const CategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`
export const CategoryWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 12px 12px;
  border-radius: 16px;
  background: ${p => p.theme.colors.blocks?.lightGray};
  cursor: pointer;
  border: 2px solid ${p => (p.isActive ? p.theme.colors.blocks.blue : 'transparent')};
`
export const SubCategoriesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`
export const SubCategoryWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  height: 48px;
  padding: 12px 12px;
  border-radius: 16px;
  gap: 12px;
  background: ${p => p.theme.colors.blocks?.lightGray};
  cursor: pointer;
  border: 2px solid ${p => (p.isActive ? p.theme.colors.blocks.blue : 'transparent')};
`
export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 21%);
  width: 100%;
  padding-top: 28px;
  gap: 20px;
`
