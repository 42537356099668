import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  background: ${p => p.theme.colors.background};
  padding-bottom: 40px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  gap: 16px;

  @media screen and (min-width: 1470px) {
    max-width: 1360px;
  }
`
export const NoDataOrLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 152px);
  width: 100%;
`
export const ChartsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`
export const SentimentChartWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
`
export const NpsChartWrapper = styled.div`
  max-width: 400px;
  width: 100%;
`
export const SentimentAndNpsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`
export const AverageBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`
export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
  width: 100%;
`
