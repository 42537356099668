import React from 'react'
import Switcher from 'Components/Kit/Switcher'
import Select from 'Components/Kit/Select'
import styled from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'
import { ITopic } from 'Types/topic'

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`

type ReviewsFilterProps = {
  topics: ITopic[]
  activeType: number
  setActiveType: (value: number) => void
  setActiveReviewsTopic: (value: string) => void
}

const ReviewsFilter = ({
  topics,
  setActiveReviewsTopic,
  setActiveType,
  activeType,
}: ReviewsFilterProps) => {
  const t = useTranslation()

  const buttons = [t('ReviewsFilter.positive'), t('ReviewsFilter.negative')]

  const modifiedTopics = topics.map(topic => ({
    label: topic.name,
    value: topic.id.toString(),
  }))

  return (
    <FilterWrapper>
      <Switcher
        firstSelectedIndex={activeType}
        onChange={setActiveType}
        width={226}
        buttons={buttons}
      />
      <Select
        variant={'small'}
        items={modifiedTopics}
        title={t('Item.topicsFilter')}
        onChange={setActiveReviewsTopic}
      />
    </FilterWrapper>
  )
}

export default ReviewsFilter
