import { useFetch } from 'Hooks/useFetch'
import { IGroup } from 'Types/category'
import { ISimpleItem } from 'Types/product'
import { ISimpleTopic, TopicsResponseType } from 'Types/topic'
import { IRating } from 'Types/rating'
import { ISentiment } from 'Types/sentiment'
import { IMarketplace } from 'Types/marketplace'
import { FourPItemType, SentimentResponseType } from 'Types/charts'
import { NpsType } from '../../../Types/nps'

type UseGroupDataProps = {
  activeMarketplace: string
  topProductsType: number
  id: string
  date__gte: string
  date__lte: string
  discussedTopicsType: number
  activeSentimentPlotTopic: string
}

export const useGroupData = (props: UseGroupDataProps) => {
  const {
    activeMarketplace,
    activeSentimentPlotTopic,
    discussedTopicsType,
    topProductsType,
    id,
    date__gte,
    date__lte,
  } = props

  const requiredRequestParams = {
    marketplace: activeMarketplace,
    date__gte,
    date__lte,
  }

  const { data: group } = useFetch<IGroup | null>(`categories/${id}`, null)

  const { data: topItems } = useFetch<ISimpleItem[]>('products/top/', [], {
    category: +id,
    sort: topProductsType === 0 ? 'positive' : 'negative',
    ...requiredRequestParams,
  })

  const { data: discussedTopics } = useFetch<ISimpleTopic[]>('topics/top/', [], {
    category: +id,
    sentiment: discussedTopicsType === 0 ? 'positive' : 'negative',
    ...requiredRequestParams,
  })

  const { data: topics } = useFetch<TopicsResponseType | null>(`topics/`, null, {
    category: +id,
    ...requiredRequestParams,
  })

  const { data: rating } = useFetch<IRating | null>('products/rating/', null, {
    category: +id,
    ...requiredRequestParams,
  })

  const { data: sentiment } = useFetch<ISentiment>(
    'products/sentiment/',
    { score: 0, difference: 0 },
    {
      category: +id,
      ...requiredRequestParams,
    },
  )

  const { data: marketplaces } = useFetch<IMarketplace[]>('marketplaces/me/', [], {
    category: +id,
  })

  const { data: lineChartData, isLoading: isLineChartLoading } =
    useFetch<SentimentResponseType | null>(
      `plots/review_sentiment_over_time_plot`,
      null,
      {
        topic: activeSentimentPlotTopic,
        category: +id,
        ...requiredRequestParams,
      },
    )

  const { data: nps, isLoading: isNpsLoading } = useFetch<NpsType | null>(
    'products/nps/',
    null,
    {
      category: +id,
      ...requiredRequestParams,
    },
  )

  const { data: fourPDataPlace } = useFetch<FourPItemType | null>('/4p/place/', null, {
    category: +id,
    ...requiredRequestParams,
  })
  const { data: fourPDataPromotion } = useFetch<FourPItemType | null>(
    '/4p/promotion/',
    null,
    {
      category: +id,
      ...requiredRequestParams,
    },
  )
  const { data: fourPDataPrice } = useFetch<FourPItemType | null>('/4p/price/', null, {
    category: +id,
    ...requiredRequestParams,
  })
  const { data: fourPDataProduct } = useFetch<FourPItemType | null>(
    '/4p/product/',
    null,
    {
      category: +id,
      ...requiredRequestParams,
    },
  )

  return {
    group,
    topItems,
    discussedTopics,
    topics,
    rating,
    sentiment,
    marketplaces,
    lineChartData,
    nps,
    fourPDataProduct,
    fourPDataPrice,
    fourPDataPromotion,
    fourPDataPlace,
  }
}
