import React from 'react'
import { Wrapper, BottomWrapper } from './styled'
import Text from 'Components/Kit/Text'
import { useNavigate } from 'react-router-dom'
import Tag from 'Components/Kit/Tag'
import { useTheme } from 'styled-components'
import NoData from 'Components/Kit/NoData'
import { useTranslation } from 'Hooks/useTranslation'

interface GroupCardProps {
  id: number
  productsCount: number
  name: string
  difference: number
}

const GroupCard = (props: GroupCardProps) => {
  const { id, name, productsCount, difference } = props
  const t = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const percentage = +(Math.abs(difference) * 100).toFixed(0)
  const onCardClick = () => {
    navigate(`/group/${id}`)
  }

  return (
    <Wrapper onClick={onCardClick}>
      <Text fontSize={16} fontWeight={700}>
        {name}
      </Text>
      <BottomWrapper>
        <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
          {t('Overview.items')}: {productsCount}
        </Text>
        {percentage !== 0 && (
          <Tag
            content={`${percentage}%`}
            withIcon={difference !== 0}
            view={difference >= 0 ? 'green' : 'red'}
          />
        )}
      </BottomWrapper>
    </Wrapper>
  )
}

export default GroupCard
