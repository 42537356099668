import React, { useState } from 'react'
import {
  AddItemIcon,
  ButtonsWrapper,
  ChoseGroupWrapper,
  DeleteIcon,
  Group,
  GroupsWrapper,
  ItemNameAndImageWrapper,
  ItemsWrapper,
  ItemWrapper,
  ScrollWrapper,
  Wrapper,
} from './styled'
import Text from 'Components/Kit/Text'
import Delete from 'Assets/Delete'
import ItemImage from 'Components/ItemImage'
import { css, useTheme } from 'styled-components'
import { IGroup } from 'Types/category'
import SingleArrow from 'Assets/SingleArrow'
import { useTranslation } from 'Hooks/useTranslation'
import GenericModal from 'Components/Kit/GenericModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import useSelectedItemsStore from 'Store/useSelectedItemsStore'
import Cross from 'Assets/Cross'
import SearchItems from 'Components/SearchItems'
import { IItem } from 'Types/product'
import Button from 'Components/Kit/Button'
import CreateGroup from '../CreateGroup'
import { useRequest } from 'Hooks/useRequest'

type AddToGroupProps = {
  groups: IGroup[]
  items: IItem[]
}

const AddToGroup = NiceModal.create(({ groups, items }: AddToGroupProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const modal = useModal()
  const { selectedItems, setSelectedItems } = useSelectedItemsStore()

  const [isSearchItemsActive, setIsSearchItemsActive] = useState(false)

  const { requestData: addItemToCategory } = useRequest()
  const onItemDelete = (id: number) => {
    const filteredItems = selectedItems.filter(item => item.id !== id)
    setSelectedItems(filteredItems)
  }
  const handleAddToGroup = async (groupId: number) => {
    const itemsIds = selectedItems.map(item => item.id)
    try {
      await addItemToCategory('PATCH', `products/bulk_update_category/`, {
        product_ids: itemsIds,
        new_category_id: groupId,
      })
      modal.resolve()
    } catch (e) {
      console.log(e)
    } finally {
      modal.remove()
    }
  }
  const showCreateGroupModal = () => {
    modal.remove()
    NiceModal.show(CreateGroup, { items })
  }

  return (
    <GenericModal title={t('Overview.addToGroup')} onClose={() => modal.remove()}>
      <Wrapper>
        <ScrollWrapper>
          {isSearchItemsActive ? (
            <SearchItems items={items} />
          ) : (
            <>
              <ItemsWrapper>
                {selectedItems.map(item => (
                  <ItemWrapper key={item.id}>
                    <ItemNameAndImageWrapper>
                      <ItemImage link={item.details.imgUrl} />
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        styledFragment={css`
                          max-width: 450px;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        `}
                      >
                        {item.details.text}
                      </Text>
                    </ItemNameAndImageWrapper>
                    {selectedItems.length > 1 && (
                      <DeleteIcon onClick={() => onItemDelete(item.id)}>
                        <Delete />
                      </DeleteIcon>
                    )}
                  </ItemWrapper>
                ))}
                <ItemNameAndImageWrapper>
                  <AddItemIcon onClick={() => setIsSearchItemsActive(true)}>
                    <Cross fill={theme.colors.icons.blue} rotate={90} />
                  </AddItemIcon>
                  <Text fontSize={14} fontWeight={700} color={theme.colors.text.blue}>
                    {t('Overview.addItem')}
                  </Text>
                </ItemNameAndImageWrapper>
              </ItemsWrapper>
              <ChoseGroupWrapper>
                <Text fontSize={16} fontWeight={500}>
                  {t('Overview.chooseGroup')}
                </Text>
                <GroupsWrapper>
                  {groups.map(group => (
                    <Group key={group.id} onClick={() => handleAddToGroup(group.id)}>
                      <Text fontSize={15} fontWeight={700}>
                        {group.name}
                      </Text>
                      <SingleArrow width={6} height={8} rotate={-90} />
                    </Group>
                  ))}
                </GroupsWrapper>
              </ChoseGroupWrapper>
            </>
          )}
        </ScrollWrapper>
        <ButtonsWrapper>
          {isSearchItemsActive ? (
            <Button
              onClick={() => setIsSearchItemsActive(false)}
              buttonSize={'large'}
              fragment={css`width: 165px`}
            >
              {t('Overview.addItem')}
            </Button>
          ) : (
            <Button onClick={showCreateGroupModal} buttonSize={'large'}>
              {t('Overview.newGroup')}
            </Button>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </GenericModal>
  )
})

export default AddToGroup
