import React, { useState } from 'react'
import { Container, FormWrapper, ToggleForms } from './styled'
import Text from 'Components/Kit/Text'
import Yandex from './Components/Yandex'
import Email from './Components/Email'
import { css, useTheme } from 'styled-components'

const Login = () => {
  const theme = useTheme()
  const [isEmailFormActive, setIsEmailFormActive] = useState(false)
  return (
    <Container>
      <FormWrapper>
        <Text fontSize={32} fontWeight={400}>
          Логин
        </Text>
        {isEmailFormActive ? <Email /> : <Yandex />}
        <ToggleForms>
          <Text fontSize={14} fontWeight={400}>
            {isEmailFormActive ? 'Нет аккаунта ?' : 'Уже есть аккаунт ?'}
          </Text>
          <Text
            fontSize={14}
            fontWeight={400}
            color={theme.colors.text.blue}
            styledFragment={css`cursor: pointer`}
            onClick={() => setIsEmailFormActive(!isEmailFormActive)}
          >
            Войти
          </Text>
        </ToggleForms>
      </FormWrapper>
    </Container>
  )
}

export default Login
