import React, { ChangeEvent, ReactNode, useState } from 'react'
import { FlattenSimpleInterpolation, useTheme } from 'styled-components'
import {
  LeftIconWrapper,
  LeftStyledInputIcon,
  RightIconWrapper,
  RightStyledInputIcon,
  StyledInput,
  StyledInputWrapper,
} from './styled'
import Text from '../Text'

type InputProps = {
  onChange: (newValue: string) => void
  value: string
  placeholder: string
  isValueValid: boolean
  inputSize?: 'large' | 'small'
  type?: string
  invalidMessage?: string
  disabled?: boolean
  fragment?: FlattenSimpleInterpolation
  rightIconSrc?: ReactNode | string
  leftIconSrc?: ReactNode | string
  onIconClick?: () => void
}
const Input = (props: InputProps) => {
  const {
    onChange,
    fragment,
    type,
    disabled,
    invalidMessage,
    placeholder,
    isValueValid,
    value,
    onIconClick,
    leftIconSrc,
    rightIconSrc,
    inputSize = 'large',
  } = props

  const theme = useTheme()

  const [isValueChanged, setIsValueChanged] = useState<boolean>(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setIsValueChanged(true)
    onChange(newValue)
  }

  return (
    <StyledInputWrapper>
      {leftIconSrc && typeof leftIconSrc === 'string' ? (
        <RightStyledInputIcon onClick={onIconClick} src={leftIconSrc} alt="icon" />
      ) : (
        <LeftIconWrapper>{leftIconSrc}</LeftIconWrapper>
      )}
      <StyledInput
        isErrorShown={isValueChanged && !isValueValid}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        fragment={fragment}
        inputSize={inputSize}
      />
      {!isValueValid && isValueChanged && (
        <Text fontSize={12} fontWeight={400} color={theme.colors.input.errorColor}>
          {invalidMessage}
        </Text>
      )}
      {rightIconSrc && typeof rightIconSrc === 'string' ? (
        <LeftStyledInputIcon onClick={onIconClick} src={rightIconSrc} alt="icon" />
      ) : (
        <RightIconWrapper>{rightIconSrc}</RightIconWrapper>
      )}
    </StyledInputWrapper>
  )
}

export default Input
