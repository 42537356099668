import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
`

export const SubscriptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
`
export const SubscriptionWrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  width: 384px;
  height: 408px;
  padding: 28px 32px;
  background: ${p => p.theme.colors.blocks.light};
  border-radius: 24px;
  border: 4px solid ${p => (p.isActive ? p.theme.colors.blocks.blue : 'transparent')};
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`
export const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`
export const PointWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const SaleWrapper = styled.div`
  position: absolute;
  right: 27px;
  top: 28px;
  border-radius: 9px;
  padding: 8px 12px;
  transform: rotate(-6deg);
  background: ${p => p.theme.colors.blocks.blue};
`
export const SaleText = styled.div<{ isActive: boolean }>`
  position: relative;
  display: inline;
  color: ${p => (p.isActive ? p.theme.colors.text.light : p.theme.colors.text.main)};

  :before {
    border-bottom: 2px solid ${p =>
      p.isActive ? p.theme.colors.text.light : p.theme.colors.text.main};
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
`
