import AllegroIcon from 'Assets/Static/Allegro.svg'
import DoubleGisIcon from 'Assets/Static/2gis.svg'
import AmazonIcon from 'Assets/Static/Amazon.svg'
import GoogleMapsIcon from 'Assets/Static/GoogleMaps.svg'
import OzonIcon from 'Assets/Static/Ozon.svg'
import WildberriesIcon from 'Assets/Static/Wildberries.svg'
import YandexMapsIcon from 'Assets/Static/YandexMaps.svg'
import YandexMarket from 'Assets/Static/YandexMarket.svg'
import GooglePlayIcon from 'Assets/Static/GooglePlay.svg'
import AppStoreIcon from 'Assets/Static/AppStore.svg'
import VKICon from 'Assets/Static/Vk.svg'
import InstagramIcon from 'Assets/Static/Instagram.svg'
import TelegramIcon from 'Assets/Static/Telegram.svg'
export const getMarketplaceIcon = (marketplace: string) => {
  switch (marketplace.toLowerCase()) {
    case 'ozon':
      return OzonIcon
    case 'amazon':
      return AmazonIcon
    case 'wildberries':
      return WildberriesIcon
    case 'allegro':
      return AllegroIcon
    case 'smythstoys':
      return ''
    case '2gis':
      return DoubleGisIcon
    case 'yandex maps':
      return YandexMapsIcon
    case 'google maps':
      return GoogleMapsIcon
    case 'yandexmarket':
      return YandexMarket
    case 'googleplay':
      return GooglePlayIcon
    case 'appstore':
      return AppStoreIcon
    case 'instagram':
      return InstagramIcon
    case 'vk':
      return VKICon
    case 'telegram':
      return TelegramIcon
    default:
      return
  }
}
