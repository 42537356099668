import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 40px 40px 40px;
  height: 100%;
  background: ${p => p.theme.colors.background};
  min-height: 100vh;
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 40px;

  @media screen and (min-width: 1470px) {
    max-width: 1360px;
  }
`
export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`
export const ComparisonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  gap: 32px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.light};
  min-height: 600px;
  width: 100%;
`
export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  max-width: 1360px;
`
export const SearchsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`
export const NoDataOrLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 152px);
  width: 100%;
`
