import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 395px;
  max-width: 685px;
  min-width: 640px;
  padding: 28px 32px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.light};
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  height: 100%;
`
export const MainProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  max-width: 170px;
  gap: 16px;
`
export const ProductImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
`
export const StarsAndSentimentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`
export const MainIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`
