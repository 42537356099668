export default {
  'TopicTable.topic': 'Топик',
  'TopicTable.source': 'Источник',
  'TopicTable.totalReviews': 'Всего отзывов',
  'TopicTable.positive': 'Позитивные',
  'TopicTable.negative': 'Негативные',
  'TopicTable.neutral': 'Нейтральные',
  'TopicTable.toneOfReviews': 'Тональность',
  'TopicTable.multiple': 'Несколько',
}
