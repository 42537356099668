import React from 'react'
import { IconType, RotateType } from '../Types/icon'
import { useTheme } from 'styled-components'

const Cross = (props: IconType & RotateType) => {
  const theme = useTheme()
  const { fill = theme.colors.icons.light, rotate = 0 } = props
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M6.5 2L6.5 12M1.5 7H11.5"
        stroke={fill}
        strokeWidth="2.5"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default Cross
