import React from 'react'
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ChartWrapper, NoTopicsWrapper, TooltipWrapper } from './styled'
import EmptyImage from 'Assets/Static/EmptyImage.png'
import Text from 'Components/Kit/Text'
import { useTranslation } from 'Hooks/useTranslation'
import { PerceptualMappingType } from 'Types/charts'

type PerceptualMappingChartProps = {
  data: PerceptualMappingType[]
  topic_x: string
  topic_y: string
}

const CustomizeDot = (props: any) => {
  const { cx, cy, payload } = props
  return (
    <g>
      <image
        href={payload.icon_link ? payload.icon_link : EmptyImage}
        x={cx - 15}
        y={cy - 15}
        height="30px"
        width="30px"
      />
    </g>
  )
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <Text fontSize={14} fontWeight={400}>
          {payload[0].payload.name}
        </Text>
      </TooltipWrapper>
    )
  }

  return null
}

const PerceptualMappingChart = ({
  data,
  topic_x,
  topic_y,
}: PerceptualMappingChartProps) => {
  const t = useTranslation()

  return (
    <ChartWrapper>
      <Text fontSize={20} fontWeight={500}>
        {t('PerceptualMappingChart.title')}
      </Text>
      {data.length ? (
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
            <CartesianGrid />
            <XAxis
              type="number"
              dataKey="x"
              name={topic_x}
              domain={[-1, 1]}
              label={{
                value: topic_x,
                position: 'center',
                offset: -10,
                dy: 30,
              }}
              allowDataOverflow
              ticks={[-1, 0, 1]}
              tickFormatter={value =>
                value === 1
                  ? t('PerceptualMappingChart.good')
                  : value === -1
                  ? t('PerceptualMappingChart.bad')
                  : t('PerceptualMappingChart.neutral')
              }
            />
            <RechartsTooltip content={<CustomTooltip />} />
            <YAxis
              type="number"
              dataKey="y"
              name={topic_y}
              domain={[-1, 1]}
              label={{
                value: topic_y,
                angle: -90,
                position: 'center',
                offset: -10,
                dx: -30,
              }}
              allowDataOverflow
              ticks={[-1, 0, 1]}
              tickFormatter={value =>
                value === 1
                  ? t('PerceptualMappingChart.good')
                  : value === -1
                  ? t('PerceptualMappingChart.bad')
                  : t('PerceptualMappingChart.neutral')
              }
            />
            <Scatter
              name="Products"
              data={data}
              fill="#8884d8"
              shape={<CustomizeDot />}
            />
          </ScatterChart>
        </ResponsiveContainer>
      ) : (
        <NoTopicsWrapper>
          <Text fontSize={14} fontWeight={400}>
            {t('PerceptualMappingChart.noTopics')}
          </Text>
        </NoTopicsWrapper>
      )}
    </ChartWrapper>
  )
}

export default PerceptualMappingChart
