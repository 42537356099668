import React from 'react'
import { IconType, RotateType } from 'Types/icon'
import { useTheme } from 'styled-components'

const SingleArrow = (props: IconType & RotateType) => {
  const theme = useTheme()
  const {
    width = 7,
    height = 5,
    fill = theme.colors.icons.dark,
    rotate = 0,
    viewBox = '0 0 7 5',
  } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)`, translate: 'all .3s ease' }}
    >
      <path
        d="M2.705 4.81539C2.67278 4.78714 2.535 4.68047 2.42167 4.5811C1.70889 3.99854 0.542222 2.47881 0.186111 1.68339C0.128889 1.56259 0.00777778 1.25718 0 1.09401C0 0.937652 0.04 0.788602 0.121111 0.646371C0.234444 0.46907 0.412778 0.326839 0.623333 0.248904C0.769444 0.198734 1.20667 0.120799 1.21444 0.120799C1.69278 0.0428641 2.47 0 3.32889 0C4.14722 0 4.89278 0.0428641 5.37833 0.106673C5.38611 0.11398 5.92944 0.191914 6.11556 0.277155C6.45556 0.433512 6.66667 0.738919 6.66667 1.06576V1.09401C6.65833 1.30687 6.44722 1.75451 6.43944 1.75451C6.08278 2.50706 4.97333 3.99172 4.23611 4.58841C4.23611 4.58841 4.04667 4.75645 3.92833 4.82952C3.75833 4.9435 3.54778 5 3.33722 5C3.10222 5 2.88333 4.93619 2.705 4.81539Z"
        fill={fill}
      />
    </svg>
  )
}

export default SingleArrow
