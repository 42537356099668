import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const TableActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`
export const TableActionButton = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  width: max-content;
  height: 36px;
  background: ${p => p.theme.colors.blocks?.light};
`
export const DeleteItemsWrapper = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 36px;
  background: ${p => p.theme.colors.blocks?.red};
  cursor: pointer;
`
