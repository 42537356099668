import styled from 'styled-components'

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 600px;
  padding: 28px 32px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.light};
`
export const NoTopicsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
export const TooltipWrapper = styled.div`
  padding: 16px 20px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  color: ${p => p.theme.colors.text.main};
  background: ${p => p.theme.colors.blocks?.light};
`
