import React from 'react'
import { Container, Wrapper } from './styled'
import BuySubscription from './Components/BuySubscription'
import Benefits from './Components/Benefits'

const Subscription = () => {
  return (
    <Container>
      <Wrapper>
        <BuySubscription />
        <Benefits />
      </Wrapper>
    </Container>
  )
}

export default Subscription
