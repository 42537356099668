export default {
  'AddItem.title': 'Add item',
  'AddItem.chooseCategory': 'Please select the source where your product is placed',
  'AddItem.chooseSubcategory': 'Please select the category of your product',
  'AddItem.addSource': 'Add source',
  'AddItem.addLinksDescription':
    'Provide links to all sources where your product is placed.',
  'AddItem.specifyYourRequestDescription':
    'Provide the analytics start date or number of reviews. You can also add both parameters.',
  'AddItem.source': 'Source',
  'AddItem.addLinkPlaceholder': 'Link',
  'AddItem.fieldRequired': 'Fields required',
  'AddItem.incorrectLink': 'We do not support this source',
  'AddItem.specifyYourRequestDate': 'Start date',
  'AddItem.specifyYourRequestCount': 'Number of reviews',
  'AddItem.specifyYourRequestLimitError': 'Exceeds remaining limit',
  'AddItem.addLinks': 'Add links',
  'AddItem.back': 'Back',
  'AddItem.addTopics': 'Add topics',
  'AddItem.editTree': 'Edit the list of topics by which we will analyze the reviews.',
  'AddItem.addTopicPlaceholder': 'Topic',
  'AddItem.addTopic': 'Add topic',
}
