import React from 'react'

const Logout = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 5.22314H15.5327C16.3277 5.22314 16.9695 5.8697 16.9695 6.65993V13.8822C16.9695 14.6724 16.3277 15.3189 15.5327 15.3189H12.5V5.22314Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.88623 4.42341L9.39294 3.7146C9.80003 3.66192 10.2167 3.78644 10.5232 4.05943C10.8345 4.33242 11.0117 4.72514 11.0117 5.14181V15.4004C11.0117 15.8171 10.8345 16.2098 10.5232 16.4828C10.2598 16.7127 9.92455 16.8372 9.57493 16.8372C9.51746 16.8372 9.4552 16.8372 9.39294 16.8276L4.88623 16.1188C4.17263 16.023 3.63623 15.4148 3.63623 14.6916V5.85062C3.63623 5.12744 4.17263 4.5192 4.88623 4.42341ZM8.33339 11.7751C8.79363 11.7751 9.16673 11.402 9.16673 10.9417C9.16673 10.4815 8.79363 10.1084 8.33339 10.1084C7.87316 10.1084 7.50006 10.4815 7.50006 10.9417C7.50006 11.402 7.87316 11.7751 8.33339 11.7751Z"
        fill="black"
      />
    </svg>
  )
}

export default Logout
