import React, { useState } from 'react'
import {
  AddItemsWrapper,
  Item,
  CheckboxWrapper,
  ImageAndNameWrapper,
  NothingFoundWrapper,
} from './styled'
import Text from 'Components/Kit/Text'
import Input from 'Components/Kit/Input'
import { css, useTheme } from 'styled-components'
import SearchIcon from 'Assets/Search'
import ItemImage from 'Components/ItemImage'
import { Checkbox } from 'antd'
import { IItem, IItemCard } from 'Types/product'
import { useTranslation } from 'Hooks/useTranslation'
import useSelectedItemsStore from 'Store/useSelectedItemsStore'

type SearchItemsProps = {
  items: IItem[] | null
}
const SearchItems = ({ items }: SearchItemsProps) => {
  const theme = useTheme()
  const t = useTranslation()
  const { selectedItems, setSelectedItems } = useSelectedItemsStore()

  const [searchedItemQuery, setSearchedItemQuery] = useState('')

  const handleCheckboxChange = (item: IItemCard) => {
    if (selectedItems.some(selectedItem => selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter(selectedItem => selectedItem.id !== item.id))
      return
    }
    setSelectedItems([...selectedItems, item])
  }

  const filteredItems =
    searchedItemQuery && items
      ? items
          .map(item => ({
            ...item,
            children: item.children?.filter(childrenItem =>
              childrenItem.details.text
                .toLowerCase()
                .startsWith(searchedItemQuery.toLowerCase()),
            ),
          }))
          .filter(item => item.children && item.children.length > 0)
      : items

  const reorderedItems = filteredItems
    ? filteredItems.sort((a, b) => {
        const aSelected = a.children?.some(child =>
          selectedItems.some(selected => selected.id === child.id),
        )
          ? 1
          : 0
        const bSelected = b.children?.some(child =>
          selectedItems.some(selected => selected.id === child.id),
        )
          ? 1
          : 0
        return bSelected - aSelected
      })
    : []

  return (
    <AddItemsWrapper>
      <Input
        fragment={css`
          padding-left: 32px;
          background: ${theme.colors.blocks?.lightGray};
        `}
        leftIconSrc={<SearchIcon />}
        onChange={e => setSearchedItemQuery(e)}
        value={searchedItemQuery}
        placeholder={t('Overview.search')}
        isValueValid
        inputSize={'small'}
      />
      {reorderedItems && reorderedItems.length > 0 ? (
        reorderedItems.map(
          item =>
            item.children?.map(itemLevelOne => (
              <Item key={itemLevelOne.id}>
                <ImageAndNameWrapper>
                  <ItemImage link={itemLevelOne.details.imgUrl} />
                  <Text fontSize={14} fontWeight={400}>
                    {itemLevelOne.details.text.length > 40
                      ? `${itemLevelOne.details.text.slice(0, 40)}...`
                      : itemLevelOne.details.text}
                  </Text>
                </ImageAndNameWrapper>
                <CheckboxWrapper>
                  <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
                    {selectedItems.some(
                      selectedItem => selectedItem.id === itemLevelOne.id,
                    )
                      ? ''
                      : itemLevelOne.category}
                  </Text>
                  <Checkbox
                    onClick={() => handleCheckboxChange(itemLevelOne)}
                    checked={selectedItems.some(
                      selectedItem => selectedItem.id === itemLevelOne.id,
                    )}
                  />
                </CheckboxWrapper>
              </Item>
            )),
        )
      ) : (
        <NothingFoundWrapper>
          <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
            {t('Overview.noResults')}
          </Text>
        </NothingFoundWrapper>
      )}
    </AddItemsWrapper>
  )
}

export default SearchItems
