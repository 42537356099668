import styled from 'styled-components'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker
export const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  border-radius: 16px;
  height: 45px;
  background: #fff;
`
