import React, { useState } from 'react'
import {
  Container,
  LogoWrapper,
  AccountIconWrapper,
  AccountInfoWrapper,
  AccountTextWrapper,
  Wrapper,
  PanelWrapper,
  LogoutWrapper,
  ReviewsWrapper,
  ReviewsAnalyzed,
  Line,
  ActiveLine,
} from './styled'
import Logo from 'Assets/Logo'
import { css, useTheme } from 'styled-components'
import AccountIcon from 'Assets/Account'
import Text from 'Components/Kit/Text'
import Navigation from 'Components/Navigation'
import Logout from 'Assets/Logout'
import { useTranslation } from 'Hooks/useTranslation'
import { useNavigate } from 'react-router-dom'
import Tooltip from 'Components/Kit/Tooltip'
import { useUserStore } from '../../Store/useUserStore'

const Header = () => {
  const { user, username, logout, isUserHasSubscription, userEmail } = useUserStore()

  const theme = useTheme()
  const navigate = useNavigate()
  const t = useTranslation()

  const [isPanelActive, setIsPanelActive] = useState(false)
  const onLogoutHandle = () => {
    setIsPanelActive(false)
    logout()
    navigate('/', { replace: true })
  }

  const displayName = userEmail ? userEmail : username

  return (
    <Container>
      <Wrapper>
        <LogoWrapper>
          <Logo fill={theme.colors.logo.fill} />
        </LogoWrapper>
        {user && isUserHasSubscription && <Navigation />}
        {user && (
          <AccountInfoWrapper>
            <AccountTextWrapper>
              <Text fontSize={16} fontWeight={700}>
                {displayName}
              </Text>
              <Tooltip
                title={
                  <Text fontSize={10} fontWeight={400}>
                    {t('Header.analyzedText')}
                  </Text>
                }
                variant={'small'}
              >
                <Text
                  fontSize={13}
                  fontWeight={700}
                  color={theme.colors.text.blue}
                  styledFragment={css`cursor: pointer;`}
                >
                  {0}/{0}
                </Text>
              </Tooltip>
            </AccountTextWrapper>
            <AccountIconWrapper onClick={() => setIsPanelActive(!isPanelActive)}>
              <AccountIcon />
            </AccountIconWrapper>
          </AccountInfoWrapper>
        )}
        <PanelWrapper isActive={isPanelActive}>
          <ReviewsAnalyzed>
            <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
              {t('Header.analyzedText').toUpperCase()}
            </Text>
            <ReviewsWrapper>
              <Line>
                <ActiveLine width={(0 / 0) * 100} />
              </Line>
              <Text fontSize={13} fontWeight={700} color={theme.colors.text.blue}>
                {0}/{0}
              </Text>
            </ReviewsWrapper>
          </ReviewsAnalyzed>
          <LogoutWrapper onClick={onLogoutHandle}>
            <Logout />
            <Text fontSize={13} fontWeight={700}>
              {t('Header.logout')}
            </Text>
          </LogoutWrapper>
        </PanelWrapper>
      </Wrapper>
    </Container>
  )
}

export default Header
