import styled, { keyframes } from 'styled-components'

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`
const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100px);
  }
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 52px;
  min-width: 110px;
  border-radius: 224px;
  background-color: ${p => p.theme.colors.blocks.dark};
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${slideUp} 0.3s ease-out forwards, ${slideDown} 0.3s ease-out 1.8s forwards;
  animation-delay: 0s, 2.1s;
  padding: 16px 24px;
`
