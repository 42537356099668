import React from 'react'
import { Wrapper, TextWrapper, ButtonsWrapper } from './styled'
import Text from 'Components/Kit/Text'
import Button from 'Components/Kit/Button'
import { css } from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'
import GenericModal from 'Components/Kit/GenericModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

const NotEnoughTopicsModal = NiceModal.create(() => {
  const t = useTranslation()
  const modal = useModal()
  const handleCloseModal = () => {
    modal.resolve()
    modal.remove()
  }

  return (
    <GenericModal title={t('Comparison.noTopics')} onClose={handleCloseModal}>
      <Wrapper>
        <TextWrapper>
          <Text fontSize={15} fontWeight={400}>
            {t('Comparison.chooseMoreTopics')}
          </Text>
        </TextWrapper>
        <ButtonsWrapper>
          <Button
            onClick={handleCloseModal}
            buttonSize={'large'}
            fragment={css`width: 100%`}
          >
            {t('Comparison.chooseButton')}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </GenericModal>
  )
})

export default NotEnoughTopicsModal
