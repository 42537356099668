import styled from 'styled-components'
export const ErrorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 12px 16px;
  min-height: 45px;
  background: #2353FF;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  border: none;
  color: #ffffff;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    background: #1D49DD;
  }
`
