import styled from 'styled-components'
export const Wrapper = styled.div<{ width: number }>`
  z-index: 10;
  height: 45px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: ${p => p.theme.colors.blocks?.light};
  position: relative;
  border-radius: 16px;
  color: ${p => p.theme.colors.text.main};
  width: ${p => p.width}px;
  padding: 4px;
  box-sizing: border-box;
`
export const Selector = styled.div<{ width: number; left: number }>`
  position: absolute;
  top: 4px;
  height: calc(100% - 8px);
  z-index: 0;
  background: ${p => p.theme.colors.blocks?.dark};
  border-radius: 12px;
  transition: width 0.3s, left 0.3s;
  width: ${p => p.width}px;
  left: ${p => p.left}px;
`
export const Button = styled.button`
  font-weight: 400;
  font-size: 13px;
  background: none;
  border: none;
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  flex: 1;

  &.selected {
    color: ${p => p.theme.colors.text?.light};
  }
`
