import dayjs from 'dayjs'

export enum ActiveStep {
  CATEGORIES = 'categories',
  SOURCES = 'sources',
  TOPICS = 'topics',
}

export type NewItem = {
  link: string
  since_date: dayjs.Dayjs | null
  parse_review_count: number | null
}
