import styled from 'styled-components'

export const Wrapper = styled.div<{ isActive: boolean }>`
  position: fixed;
  z-index: 100;
  bottom: 8px;
  right: 50%;
  transform: translateX(50%);
  display: ${p => (p.isActive ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 224px;
  gap: 16px;
  background: ${p => p.theme.colors.blocks?.dark};
  cursor: pointer;
`
export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const ItemImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  object-fit: contain;
`
export const NoItemImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.colors.blocks?.lightGray};
  border-radius: 8px;
`
