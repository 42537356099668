import { IReviewReport } from 'Types/report'
import * as XLSX from 'xlsx'

export const generateReport = (
  data: IReviewReport[],
  sheetName: string,
  fileName: string,
) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
  XLSX.writeFile(workbook, `${fileName}.xlsx`)
}
