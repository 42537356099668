import styled from 'styled-components'
import { Skeleton } from 'antd'
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  padding: 28px 32px;
  height: 395px;
  width: 450px;
  background: ${p => p.theme.colors.blocks?.light};
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const TableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`
export const ReviewsAndSentimentsWrapper = styled.table`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 32px;
`
export const TopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`
export const ReviewsCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
export const SentimentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`
export const Sentiment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`
