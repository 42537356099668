import React from 'react'
import { IconType } from 'Types/icon'
import { useTheme } from 'styled-components'

const ItemNav = (props: IconType) => {
  const theme = useTheme()
  const { fill = theme.colors.icons.dark } = props
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.67573 6.30602C10.1168 6.25185 10.5402 6.49702 10.7057 6.9024C10.7485 6.97677 10.7816 7.05617 10.804 7.13868C10.8075 9.01133 10.821 10.8529 10.8265 12.6939C10.8118 12.8843 10.8298 13.0759 10.8798 13.2606C11.002 13.6255 11.3475 13.8738 11.7386 13.8779L17.6828 13.8711L17.7221 13.8894L17.8297 13.904C18.042 13.9448 18.2354 14.0551 18.3772 14.2183C18.5427 14.4088 18.6244 14.6558 18.6045 14.905C18.1572 18.2899 15.4861 20.9735 12.0482 21.4919C8.61029 22.0103 5.2309 20.239 3.7536 17.1442C3.31807 16.2523 3.06829 15.2844 3.0189 14.2973C2.9941 14.0042 2.9937 13.7096 3.01771 13.4165C3.25637 9.80229 6.0264 6.844 9.67573 6.30602ZM13.3613 3.89587C17.4192 4.27335 20.6313 7.43847 20.9989 11.4218C21.0013 11.4456 20.9998 11.4696 20.9944 11.4929L20.9863 11.6042C20.9637 11.7506 20.8944 11.8872 20.7869 11.9936C20.6526 12.1266 20.4697 12.2014 20.2786 12.2016L13.4197 12.2223L13.307 12.2175C13.1211 12.1967 12.9469 12.1129 12.8155 11.9788C12.6579 11.8178 12.5765 11.5993 12.5914 11.3768L12.5544 4.63851L12.5614 4.52787C12.5816 4.33969 12.6773 4.16732 12.8274 4.04876C12.9774 3.9302 13.1695 3.8752 13.3613 3.89587Z"
        fill={fill}
      />
    </svg>
  )
}

export default ItemNav
