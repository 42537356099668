import React from 'react'
import { InfoIcon, StarsWrapper, TextWrapper, Wrapper } from './styled'
import Text from 'Components/Kit/Text'
import { Rate } from 'antd'
import { useTheme } from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'
import Info from 'Assets/Info'
import Tooltip from 'Components/Kit/Tooltip'
import Tag from 'Components/Kit/Tag'
import NoData from '../../Kit/NoData'

type SimpleAverageRatingProps = {
  rating: number
  difference: number
}

const SimpleAvgRating = (props: SimpleAverageRatingProps) => {
  const { rating, difference } = props
  const t = useTranslation()
  const percentage = +(Math.abs(difference) * 100).toFixed(0)

  return (
    <Wrapper>
      <Tooltip title={t('SimpleAvgRating.tooltipText')}>
        <InfoIcon>
          <Info />
        </InfoIcon>
      </Tooltip>
      {rating !== 0 ? (
        <>
          <StarsWrapper>
            <TextWrapper>
              <Text fontSize={36} fontWeight={500}>
                {rating.toFixed(1)}
              </Text>
            </TextWrapper>
            <Rate allowHalf value={+rating.toFixed(1)} disabled />
          </StarsWrapper>
          {percentage != 0 && (
            <Tag
              content={`${percentage}%`}
              withIcon
              view={difference >= 0 ? 'green' : 'red'}
            />
          )}
          <Text fontSize={14} fontWeight={400}>
            {t('SimpleAvgRating.title')}
          </Text>
        </>
      ) : (
        <NoData isTooltip={false} />
      )}
    </Wrapper>
  )
}

export default SimpleAvgRating
