import React, { useRef } from 'react'
import { NoDataWrapper, ReviewsWrapper } from './styled'
import { ITopicReview } from 'Types/review'
import Review from './Review'
import _ from 'lodash'
import NoData from 'Components/Kit/NoData'

type ReviewsProps = {
  reviews: ITopicReview[]
}

const ReviewsList = ({ reviews }: ReviewsProps) => {
  const reviewsRef = useRef<HTMLDivElement>(null)
  const uniqueReviews = _.uniqBy(reviews, 'id')

  return (
    <ReviewsWrapper ref={reviewsRef}>
      {uniqueReviews.length ? (
        <>
          {uniqueReviews.map(review => (
            <Review key={review.id} review={review} topics={review.topics} />
          ))}
        </>
      ) : (
        <NoDataWrapper>
          <NoData />
        </NoDataWrapper>
      )}
    </ReviewsWrapper>
  )
}

export default ReviewsList
