import { create } from 'zustand'

type IdsStore = {
  itemId: number | null
  groupId: number | null
  comparisonIds: number[]
  setItemId: (id: number) => void
  setGroupId: (id: number) => void
  setComparisonIds: (ids: number[]) => void
}

export const useIdsStore = create<IdsStore>(set => ({
  itemId: null,
  groupId: null,
  comparisonIds: [],
  setItemId: id => set({ itemId: id }),
  setGroupId: id => set({ groupId: id }),
  setComparisonIds: ids => set({ comparisonIds: ids }),
}))
