import React, { useRef, useState } from 'react'
import { Wrapper, CustomSelect, SelectItem, SelectItems } from './styled'
import Text from 'Components/Kit/Text'
import SingleArrow from 'Assets/SingleArrow'
import CheckMark from 'Assets/CheckMark'
import useOnClickOutside from 'Hooks/useOnClickOutside'
import { css } from 'styled-components'

type SelectProps = {
  items: { label: string; value: string }[]
  title: string
  onChange: (item: string) => void
  variant?: 'large' | 'small'
}

const Select = (props: SelectProps) => {
  const { title, items, onChange, variant = 'large' } = props
  const [isActive, setIsActive] = useState(false)
  const [selectedItem, setSelectedItem] = useState({ value: '', label: '' })

  const selectItemsRef = useRef<HTMLDivElement>(null)
  const handleChange = (item: { value: string; label: string }) => {
    onChange(item.value)
    setIsActive(false)
    setSelectedItem(item)
  }

  useOnClickOutside(selectItemsRef, () => setIsActive(false))

  return (
    <Wrapper ref={selectItemsRef}>
      <CustomSelect
        isDisabled={!items.length}
        variant={variant}
        onClick={() => setIsActive(!isActive)}
      >
        <Text
          fontSize={13}
          fontWeight={400}
          styledFragment={css`
            width: 170px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          `}
        >
          {selectedItem.label
            ? `${selectedItem.label.charAt(0).toUpperCase()}${selectedItem.label.slice(
                1,
                selectedItem.label.length,
              )}`
            : title}
        </Text>
        <SingleArrow rotate={isActive ? 180 : 0} />
      </CustomSelect>
      <SelectItems variant={variant} isActive={isActive}>
        {[{ label: title, value: '' }, ...items].map(item => (
          <SelectItem
            variant={variant}
            onClick={() => handleChange(item)}
            key={item.label}
          >
            <Text
              fontSize={13}
              fontWeight={400}
              styledFragment={css`
                width: 170px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              `}
            >
              {item.label.charAt(0).toUpperCase()}
              {item.label.slice(1, item.label.length)}
            </Text>
            {selectedItem.value === item.value && <CheckMark />}
          </SelectItem>
        ))}
      </SelectItems>
    </Wrapper>
  )
}

export default Select
