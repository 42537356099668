import React, { useEffect } from 'react'
import { Container, Wrapper } from './styled'
import Text from 'Components/Kit/Text'
import Button from 'Components/Kit/Button'
import { useNavigate } from 'react-router-dom'
import { css } from 'styled-components'
import { useFetch } from '../../Hooks/useFetch'
import { QuotaType } from '../../Types/quota'
import { useUserStore } from '../../Store/useUserStore'

const SuccessPayment = () => {
  const { user, setIsUserHasSubscription } = useUserStore()
  const navigate = useNavigate()

  const { data: quotaResponseData, fetchData: getUserQuota } = useFetch<QuotaType | null>(
    'quotas/me/',
    null,
    {},
    true,
  )

  useEffect(() => {
    if (!user) return
    getUserQuota()
  }, [user])

  useEffect(() => {
    if (!quotaResponseData) return
    if (quotaResponseData.reviews_count > 0) {
      console.log('has subs')
      setIsUserHasSubscription(true)
    }
  }, [quotaResponseData])
  console.log(quotaResponseData)
  return (
    <Container>
      <Wrapper>
        <Text fontSize={24} fontWeight={500}>
          Вы успешно купили подписку
        </Text>
        <Button
          fragment={css`width: 250px`}
          onClick={() => navigate('/overview', { replace: true })}
        >
          Продолжить
        </Button>
      </Wrapper>
    </Container>
  )
}

export default SuccessPayment
