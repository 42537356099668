import styled, { css } from 'styled-components'

const largeVariantStyles = css`
  height: 45px;
  background: ${p => p.theme.colors.blocks.light};
  border-radius: 16px;
`

const smallVariantStyles = css`
  height: 28px;
  background: ${p => p.theme.colors.blocks.gray};
  border-radius: 10px;
`
export const Wrapper = styled.div<{ width: number; variant: 'large' | 'small' }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  color: ${p => p.theme.colors.text.main};
  width: ${p => p.width}px;
  padding: 3px;
  box-sizing: border-box;

  ${p => (p.variant === 'large' ? largeVariantStyles : smallVariantStyles)}
`

const largeSelectorStyles = css`
  top: 4px;
  height: calc(100% - 8px);
  background: ${p => p.theme.colors.blocks.dark};
  border-radius: 16px;
`

const smallSelectorStyles = css`
  top: 3px;
  height: calc(100% - 6px);
  background: ${p => p.theme.colors.blocks.light};
  border-radius: 8px;
`

export const Selector = styled.div<{
  width: number
  left: number
  variant: 'large' | 'small'
}>`
  position: absolute;
  z-index: 0;
  width: ${p => p.width}px;
  left: ${p => p.left}px;
  transition: width 0.3s, left 0.3s;

  ${p => (p.variant === 'large' ? largeSelectorStyles : smallSelectorStyles)}
`

export const Button = styled.button<{ width: number; variant: 'large' | 'small' }>`
  font-weight: 400;
  font-size: 13px;
  background: none;
  border: none;
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  width: ${p => p.width}%;

  &.selected {
    color: ${p =>
      p.variant === 'large' ? p.theme.colors.text.light : p.theme.colors.text.main};
  }
`
