import React from 'react'
import {
  Wrapper,
  Title,
  SentimentsWrapper,
  ReviewsAndSentimentsWrapper,
  ReviewsCountWrapper,
  TableWrapper,
  TopicsWrapper,
  Sentiment,
} from './styled'
import Text from 'Components/Kit/Text'
import Switcher from 'Components/Kit/Switcher'
import { css, useTheme } from 'styled-components'
import SingleArrow from 'Assets/SingleArrow'
import { ISimpleTopic } from 'Types/topic'
import NoData from 'Components/Kit/NoData'
import { useTranslation } from 'Hooks/useTranslation'

type DiscussedTopicsProps = {
  topics: ISimpleTopic[]
  type: number
  setType: (value: number) => void
}
const DiscussedTopics = ({ topics, type, setType }: DiscussedTopicsProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const buttons = [t('DiscussedTopics.positive'), t('DiscussedTopics.negative')]

  return (
    <Wrapper>
      <Title>
        <Text fontSize={20} fontWeight={500}>
          {t('DiscussedTopics.title')}
        </Text>
        <Switcher
          onChange={setType}
          firstSelectedIndex={type}
          buttons={buttons}
          width={150}
        />
      </Title>
      {topics.length ? (
        <TableWrapper>
          <TopicsWrapper>
            <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
              {t('DiscussedTopics.topics').toUpperCase()}
            </Text>
            {topics.map(topic => (
              <Text
                fontSize={14}
                fontWeight={400}
                key={topic.id}
                styledFragment={css`
                width: 170px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                :first-letter {
                  text-transform: uppercase;
                }
              `}
              >
                {topic.name}
              </Text>
            ))}
          </TopicsWrapper>
          <ReviewsAndSentimentsWrapper>
            <ReviewsCountWrapper>
              <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
                {t('DiscussedTopics.reviews').toUpperCase()}
              </Text>
              {topics.map(topic => (
                <Text fontSize={14} fontWeight={400} key={topic.id}>
                  {topic.count}
                </Text>
              ))}
            </ReviewsCountWrapper>
            <SentimentsWrapper>
              <Text fontSize={10} fontWeight={700} color={theme.colors.text.gray}>
                {t('DiscussedTopics.sentiment').toUpperCase()}
              </Text>
              {topics.map(topic => (
                <Sentiment key={topic.id}>
                  {topic.difference !== 0 ? (
                    <>
                      <Text fontSize={14} fontWeight={400}>
                        {(Math.abs(topic.difference) * 100).toFixed(0)}%
                      </Text>
                      <SingleArrow
                        fill={
                          topic.difference >= 0
                            ? theme.colors.icons.green
                            : theme.colors.icons.red
                        }
                        rotate={topic.difference >= 0 ? 180 : 0}
                      />
                    </>
                  ) : (
                    <NoData />
                  )}
                </Sentiment>
              ))}
            </SentimentsWrapper>
          </ReviewsAndSentimentsWrapper>
        </TableWrapper>
      ) : (
        <NoData isTooltip={false} />
      )}
    </Wrapper>
  )
}

export default DiscussedTopics
