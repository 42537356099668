import React, { ReactNode } from 'react'
import { ModalOverlay, Wrapper, TitleWrapper, CrossIcon } from './styled'
import Text from '../Text'
import { useTheme } from 'styled-components'
import Cross from 'Assets/Cross'

type ModalProps = {
  children: ReactNode
  title?: string
  onClose?: () => void
}

const GenericModal = ({ children, title, onClose }: ModalProps) => {
  const theme = useTheme()
  return (
    <ModalOverlay>
      <Wrapper onClick={e => e.stopPropagation()}>
        <TitleWrapper>
          <Text fontSize={20} fontWeight={500}>
            {title}
          </Text>
          <CrossIcon onClick={onClose}>
            <Cross fill={theme.colors.icons.dark} rotate={45} />
          </CrossIcon>
        </TitleWrapper>
        {children}
      </Wrapper>
    </ModalOverlay>
  )
}

export default GenericModal
