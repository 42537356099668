export default {
  'Overview.title': 'Overview',
  'Overview.add': 'Add',
  'Overview.category': 'Category',
  'Overview.avgRating': 'Avg rating',
  'Overview.totalReviews': 'Total reviews',
  'Overview.toneOfReviews': 'Tone of reviews',
  'Overview.multiple': 'Multiple',
  'Overview.items': 'Items',
  'Overview.sentimentDynamics': 'Sentiment dynamics',
  'Overview.group': 'Group',
  'Overview.search': 'Search',
  'Overview.createGroup': 'Create group',
  'Overview.name': 'Name',
  'Overview.requiredFill': 'Required fill',
  'Overview.addItem': 'Add item',
  'Overview.clearAll': 'Clear all',
  'Overview.addToGroup': 'Add to group',
  'Overview.chooseGroup': 'Choose group',
  'Overview.newGroup': 'New group',
  'Overview.compare': 'Compare',
  'Overview.specifyYourRequest': 'Specify your request',
  'Overview.specifyYourRequestDescription':
    'Provide the analytics start date or number of reviews. You can also add both parameters.',
  'Overview.noItems': "You haven't added any products yet",
  'Overview.close': 'Close',
  'Overview.delete': 'Delete',
  'Overview.deleteItems': ' Delete items',
  'Overview.deleteItemsQuestion': 'Are you sure you want to delete items?',
  'Overview.noResults': 'Nothing found for this request',
  'Overview.source': 'Source',
}
