import React, { useState } from 'react'
import { ButtonsWrapper, ScrollWrapper, SearchWrapper, Wrapper } from './styled'
import Text from 'Components/Kit/Text'
import Input from 'Components/Kit/Input'
import { useValidationState, validationFuncs } from 'Hooks/useValidatedState'
import { IItem } from 'Types/product'
import { css, useTheme } from 'styled-components'
import Button from 'Components/Kit/Button'
import Loader from 'Components/Kit/Loader'
import SearchItems from 'Components/SearchItems'
import { useTranslation } from 'Hooks/useTranslation'
import GenericModal from 'Components/Kit/GenericModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { overviewRequests } from '../../API/overviewRequests'
import useSelectedItemsStore from 'Store/useSelectedItemsStore'
import { useRequest } from '../../../../Hooks/useRequest'
import { IGroup } from '../../../../Types/category'

type CreateGroupProps = {
  items: IItem[] | null
}

const CreateGroup = NiceModal.create(({ items }: CreateGroupProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const modal = useModal()
  const { selectedItems, setSelectedItems } = useSelectedItemsStore()

  const [isLoading, setIsLoading] = useState(false)
  const [[name, setName], isNameValid] = useValidationState<string>(
    '',
    validationFuncs.hasValue,
  )

  const { requestData: addItemToCategory } = useRequest()
  const { requestData: createCategory, data: createCategoryData } = useRequest<IGroup>()

  const handleCreateGroup = async () => {
    setIsLoading(true)
    try {
      const selectedItemsIds = selectedItems.map(item => item.id)
      await createCategory('POST', 'categories/', { name })
      const response = await overviewRequests.createCategory(name)
      await addItemToCategory('PATCH', `products/bulk_update_category/`, {
        product_ids: selectedItemsIds,
        new_category_id: response.data.id,
      })
      modal.resolve()
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
      setSelectedItems([])
      setName('')
      modal.remove()
    }
  }

  return (
    <GenericModal title={t('Overview.createGroup')} onClose={() => modal.remove()}>
      <Wrapper>
        <ScrollWrapper>
          <Input
            fragment={css`
              background: ${theme.colors.blocks?.lightGray};
            `}
            onChange={setName}
            value={name}
            placeholder={t('Overview.name')}
            isValueValid={isNameValid}
            invalidMessage={t('Overview.requiredFill')}
            inputSize={'large'}
          />
          <SearchWrapper>
            <Text fontSize={16} fontWeight={500}>
              {t('Overview.addItem')}
            </Text>
            <SearchItems items={items} />
          </SearchWrapper>
        </ScrollWrapper>
        <ButtonsWrapper>
          <Button
            disabled={!(isNameValid && selectedItems.length)}
            fragment={css`width: 135px;`}
            onClick={handleCreateGroup}
            buttonSize={'large'}
          >
            {isLoading ? (
              <Loader size={18} color={theme.colors.icons.light} />
            ) : (
              t('Overview.createGroup')
            )}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </GenericModal>
  )
})
export default CreateGroup
