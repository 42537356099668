import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Text as RechartsText } from 'recharts'
import { ChartWrapper } from './styled'
import { useTheme } from 'styled-components'
import NoData from 'Components/Kit/NoData'
import Text from 'Components/Kit/Text'
import { PopularTopicType } from 'Types/popularTopics'
import { useTranslation } from '../../../Hooks/useTranslation'

type TopicDonutChartProps = {
  data: PopularTopicType[]
}
const TopicDonutChart = ({ data }: TopicDonutChartProps) => {
  const t = useTranslation()

  const filteredData = data.filter(item => item.value !== 0)
  const totalValue = filteredData.reduce((sum, item) => sum + item.value, 0)

  const colors = [
    '#2353FF',
    '#3965FF',
    '#4F75FF',
    '#6587FF',
    '#7B98FF',
    '#91A9FF',
    '#A7BAFF',
    '#BDCBFF',
  ]

  const isEmptyData = data.every(item => item.value === 0)

  return (
    <ChartWrapper>
      <Text fontSize={20} fontWeight={500}>
        {t('TopicsDonutChart.title')}
      </Text>
      {!isEmptyData ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={filteredData}
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={93}
              paddingAngle={5}
              dataKey="value"
              cornerRadius={20}
              labelLine={true}
              label={({ name, value }) => {
                const percentage = ((value / totalValue) * 100).toFixed(1)
                return `${
                  name.length > 20 ? `${name.slice(0, 20)}...` : name
                }: ${percentage}%`
              }}
              style={{
                fontSize: 14,
              }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <NoData isTooltip={false} />
      )}
    </ChartWrapper>
  )
}

export default TopicDonutChart
