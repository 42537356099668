import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 600px;
  height: 600px;
`
export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
  padding-top: 28px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin-bottom: 55px;
`
export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 21%);
  width: 100%;
  gap: 32px;
`
