import styled, { css, CSSProp } from 'styled-components'

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`

export const StyledInput = styled.input<{
  fragment?: CSSProp
  inputSize: 'large' | 'small'
  isErrorShown: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
  border-radius: 16px;
  border: 2px solid ${p =>
    p.isErrorShown ? p.theme.colors.input.errorBorder : p.theme.colors.input.background};
  color: ${props => props.theme.colors.input.text};
  background: ${props => props.theme.colors.input.background};
  outline: none;
  font-family: 'Helvetica Neue', serif;
  font-size: 15px;
  font-weight: 400;
  caret-color: ${p => p.theme.colors.text.blue};
  transition: all .3s ease;

  ::placeholder {
    color: ${props => props.theme.colors.input.placeholder};
  }

  &:active,
  &:focus {
    border: 2px solid ${p => p.theme.colors.input.activeBorder};
  }

  ${({ inputSize }) =>
    inputSize === 'small' &&
    css`
            padding: 8px 12px;
            border-radius: 12px;
            font-size: 13px;
          `};

  ${({ fragment }) => fragment};
`

export const RightIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  cursor: pointer;
`

export const LeftIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`

export const RightStyledInputIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const LeftStyledInputIcon = styled.img`
  width: 24px;
  height: 24px;
`
