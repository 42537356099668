import React from 'react'
import ErrorLayout from '../ErrorLayout'
import { StyledButtonsGrid } from '../ErrorLayout/styled'
import { ErrorButton } from '../styled'

type ErrorPageProps = {
  title: string
  code: string | number
}

const NotFound = ({ code, title }: ErrorPageProps) => {
  return (
    <ErrorLayout title={code} subtitle={title}>
      <StyledButtonsGrid>
        <ErrorButton onClick={() => window.location.replace('/overview')}>
          На главную
        </ErrorButton>
      </StyledButtonsGrid>
    </ErrorLayout>
  )
}

export default NotFound
