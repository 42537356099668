import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 28px 32px;
  border-radius: 24px;
  width: 100%;
  background: ${p => p.theme.colors.blocks?.light};
`
export const TitleAndFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`
