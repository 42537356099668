import React from 'react'
import styled from 'styled-components'

type CircleProgressBarProps = {
  percentage: number
  mainStroke: string
  secondaryStroke: string
}

const CircleBackground = styled.circle<{ stroke: string }>`
  fill: none;
  stroke: ${p => p.stroke};
  stroke-linecap: round;
  stroke-linejoin: round;
`

const CircleProgress = styled.circle<{ stroke: string }>`
  fill: none;
  stroke: ${p => p.stroke};
  stroke-linecap: round;
  stroke-linejoin: round;
`

const CircleText = styled.text`
  font-size: 36px;
  font-weight: 500;
`

const CircleProgressBar = ({
  percentage,
  mainStroke,
  secondaryStroke,
}: CircleProgressBarProps) => {
  const radius = 70
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * percentage) / 2 / 100

  return (
    <div>
      <svg width={200} height={200} viewBox={'0 0 200 200'}>
        <CircleBackground
          stroke={secondaryStroke}
          cx={100}
          cy={100}
          r={radius}
          strokeWidth="20"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: 219,
          }}
          transform="rotate(180, 100, 100)"
        />
        <CircleProgress
          stroke={mainStroke}
          cx={100}
          cy={100}
          r={radius}
          strokeWidth="20"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          transform="rotate(180, 100, 100)"
        />
        <CircleText x={'50%'} y={'50%'} dy={'0.3rem'} textAnchor={'middle'}>
          {percentage}%
        </CircleText>
      </svg>
    </div>
  )
}

export default CircleProgressBar
