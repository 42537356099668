import React from 'react'
import { IconType } from 'Types/icon'
import { useTheme } from 'styled-components'

const ReviewsNav = (props: IconType) => {
  const theme = useTheme()
  const { fill = theme.colors.icons.dark } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15.3636L17 15.3636M7 12.1818H12M7 9.00001L17 9"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReviewsNav
