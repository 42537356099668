import React from 'react'
import { Navigate, useOutlet } from 'react-router-dom'
import { useUserStore } from 'Store/useUserStore'
import { PublicRoutes } from './types'

const ProtectedRoute = () => {
  const { user } = useUserStore()
  const outlet = useOutlet()

  return !user ? <Navigate to={PublicRoutes.START} replace /> : outlet
}

export default ProtectedRoute
