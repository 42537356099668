import styled, { CSSProp } from 'styled-components'

export const ChartWrapper = styled.div<{ customStyleWrapper?: CSSProp }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 395px;
  padding: 28px 32px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.light};

  ${({ customStyleWrapper }) => customStyleWrapper && customStyleWrapper}
`
export const FiltersAndTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`
export const SentimentItem = styled.div<{
  background: string
  borderColor: string
  isActive: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid ${p => p.borderColor};
  background: ${p => p.background};
  cursor: pointer;
  opacity: ${p => (p.isActive ? 1 : 0.5)};
`
export const SentimentItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`
