import React, { useState } from 'react'
import { LoaderWrapper, Wrapper } from './styled'
import ReviewsFilter from 'Components/ReviewsFilter'
import ReviewsList from 'Components/ReviewsList'
import { useFetch } from 'Hooks/useFetch'
import { IReview, ReviewsResponseType } from 'Types/review'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'Hooks/useTranslation'
import { TopicsResponseType } from 'Types/topic'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import GenericModal from 'Components/Kit/GenericModal'
import Loader from 'Components/Kit/Loader'

type ReviewsModalProps = {
  id: string
}

const ReviewsModal = NiceModal.create(({ id }: ReviewsModalProps) => {
  const location = useLocation()
  const modal = useModal()
  const t = useTranslation()
  const [activeReviewsType, setActiveReviewsType] = useState(0)
  const [activeReviewsTopic, setActiveReviewsTopic] = useState('')

  const queryParams = location.pathname.includes('/group')
    ? { category: +id }
    : { product: +id }

  const { data: reviews, isLoading: isReviewsLoading } =
    useFetch<ReviewsResponseType | null>(`reviews/`, null, {
      ...queryParams,
      is_positive: activeReviewsType === 0 ? 1 : 0,
      topic: activeReviewsTopic,
    })

  const { data: topics } = useFetch<TopicsResponseType | null>(`topics/`, null, {
    ...queryParams,
  })

  return (
    <GenericModal title={t('ReviewsModal.reviews')} onClose={() => modal.remove()}>
      <Wrapper>
        <ReviewsFilter
          topics={topics?.results || []}
          activeType={activeReviewsType}
          setActiveType={setActiveReviewsType}
          setActiveReviewsTopic={setActiveReviewsTopic}
        />
        {isReviewsLoading ? (
          <LoaderWrapper>
            <Loader size={25} />
          </LoaderWrapper>
        ) : (
          <ReviewsList reviews={reviews?.results || []} />
        )}
      </Wrapper>
    </GenericModal>
  )
})

export default ReviewsModal
