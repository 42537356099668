export enum PublicRoutes {
  START = 'start',
  LOGIN_YANDEX = 'oauth/yandex',
}

export enum PrivateRoutes {
  OVERVIEW = 'overview',
  CURRENT_PRODUCT = 'products/:id',
  GROUP = 'group/:id',
  COMPARISON = 'comparison',
  SUBSCRIPTION = 'subscription',
  REVIEWS = 'reviews',
  SUCCESS_PAYMENT = 'success-payment',
  FAILED_PAYMENT = 'failed-payment',
}

export type IRouteParams = {
  id: string
}
