import styled, { FlattenSimpleInterpolation } from 'styled-components'

export const CustomText = styled.span<{
  color: string,
  fontSize: number,
  fontWeight: number,
  styledFragment?: FlattenSimpleInterpolation
}>`
  font-family: 'Helvetica Neue', serif;
	color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};

  ${({ styledFragment }) => styledFragment};
`