import React, { useEffect } from 'react'
import {
  ButtonWrapper,
  PointsWrapper,
  PointWrapper,
  SaleText,
  SaleWrapper,
  SubscriptionsWrapper,
  SubscriptionWrapper,
  TitleWrapper,
  Wrapper,
} from './styled'
import Text from 'Components/Kit/Text'
import CheckMark from 'Assets/CheckMark'
import Button from 'Components/Kit/Button'
import { useTheme } from 'styled-components'
import { useRequest } from '../../../../Hooks/useRequest'
import { useUserStore } from '../../../../Store/useUserStore'

const subscriptions = [
  {
    id: 1,
    name: 'Базовый',
    price: 2000,
    reviewsCount: 1200,
    salePercentage: 25,
    buttonText: 'Купить базовый план',
    points: [
      { id: 1, name: 'Лимит 1200 отзывов' },
      { id: 2, name: '3 продукта для анализа' },
      { id: 3, name: '12 источник для всех продуктов' },
    ],
  },
  {
    id: 2,
    name: 'Про',
    price: 4000,
    reviewsCount: 2000,
    salePercentage: 40,
    buttonText: 'Купить про план',
    points: [
      { id: 1, name: 'Лимит 2000 отзывов' },
      { id: 2, name: '6 продукта для анализа' },
      { id: 3, name: '18 источник для всех продуктов' },
    ],
  },
  {
    id: 3,
    name: 'Про+',
    price: 6000,
    reviewsCount: 4000,
    salePercentage: 55,
    buttonText: 'Купить про+ план',
    points: [
      { id: 1, name: 'Лимит 4000 отзывов' },
      { id: 2, name: '9 продукта для анализа' },
      { id: 3, name: '27 источник для всех продуктов' },
      { id: 4, name: 'Редактирование тем' },
      { id: 5, name: 'Доступ к модели Napoleon IT Lite' },
    ],
  },
]

const BuySubscription = () => {
  const { username } = useUserStore()
  const theme = useTheme()

  const { requestData: buySubscription, data: buySubscriptionResponse } = useRequest<{
    payment_url: string
  }>()
  const handleBuySubscription = async (subscription: any) => {
    await buySubscription('POST', 'pays/init/', {
      'amount':
        Number(
          (
            subscription.price -
            subscription.price * (subscription.salePercentage / 100)
          ).toFixed(0),
        ) * 100,
      'description': `Подписка ${subscription.name}`,
      'email': username,
      'reviews_count': 100,
      'max_product': 10,
    })
  }

  useEffect(() => {
    if (!buySubscriptionResponse) return
    window.open(buySubscriptionResponse.payment_url, '_blank')
  }, [buySubscriptionResponse])

  return (
    <Wrapper>
      <Text fontSize={40} fontWeight={400}>
        Тарифы Napoleon IT Отзывы
      </Text>
      <SubscriptionsWrapper>
        {subscriptions.map(subscription => (
          <SubscriptionWrapper key={subscription.id} isActive={subscription.id === 2}>
            <SaleWrapper>
              <Text fontSize={20} fontWeight={300} color={theme.colors.text.light}>
                -{subscription.salePercentage} %
              </Text>
            </SaleWrapper>
            <TitleWrapper>
              <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
                {subscription.name}
              </Text>
              <Text fontSize={48} fontWeight={600}>
                {subscription.reviewsCount}{' '}
                <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
                  Отзывов
                </Text>
              </Text>
            </TitleWrapper>
            <PointsWrapper>
              {subscription.points.map(point => (
                <PointWrapper key={point.id}>
                  <CheckMark />
                  <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
                    {point.name}
                  </Text>
                </PointWrapper>
              ))}
            </PointsWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => handleBuySubscription(subscription)}
                buttonType={subscription.id !== 2 ? 'bordered' : 'main'}
              >
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={
                    subscription.id === 2
                      ? theme.colors.text.light
                      : theme.colors.text.main
                  }
                >
                  Подключить за{' '}
                  <SaleText isActive={subscription.id === 2}>
                    {subscription.price} ₽
                  </SaleText>{' '}
                  {(
                    subscription.price -
                    subscription.price * (subscription.salePercentage / 100)
                  ).toFixed(0)}{' '}
                  ₽/нед.
                </Text>
              </Button>
              <Text fontSize={12} fontWeight={400} color={theme.colors.text.gray}>
                Акция действует до 15 июля
              </Text>
            </ButtonWrapper>
          </SubscriptionWrapper>
        ))}
      </SubscriptionsWrapper>
    </Wrapper>
  )
}

export default BuySubscription
