import styled, { css, CSSProp } from 'styled-components'

const mainStyles = () => css`
  background: ${p => p.theme.colors.button.background};
  color: ${p => p.theme.colors.button.color};

  &:hover {
    background: ${p => p.theme.colors.button.hover};
  }
`
const secondaryStyles = () => css`
  background: ${p => p.theme.colors.button.secondaryBackground};
  color: ${p => p.theme.colors.button.secondaryColor};

  &:hover {
    background: ${p => p.theme.colors.button.secondaryHover};
  }
`
const borderedStyles = () => css`
  background: transparent;
  border: 2px solid ${p => p.theme.colors.button.background};
  color: ${p => p.theme.colors.button.secondaryColor};

  &:hover {
    background: ${p => p.theme.colors.button.secondaryHover};
  }
`

export const StyledButton = styled.button<{
  buttonType: 'main' | 'secondary' | 'bordered'
  buttonSize: 'large' | 'small'
  fragment?: CSSProp
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 12px 16px;
  min-height: 45px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  border: none;
  transition: all .3s ease;
  cursor: pointer;

  ${({ buttonType, disabled }) => {
    switch (buttonType) {
      case 'main':
        return mainStyles()
      case 'secondary':
        return secondaryStyles()
      case 'bordered':
        return borderedStyles()
      default:
        return mainStyles()
    }
  }}

  ${({ buttonSize }) =>
    buttonSize === 'small' &&
    css`
            padding: 8px 12px;
            gap: 8px;
            border-radius: 12px;
            font-size: 13px;
            font-weight: 700;
          `};

  ${({ disabled }) =>
    disabled &&
    css`
            pointer-events: none;
            background: ${p => p.theme.colors.button.disabled};
            color: ${p => p.theme.colors.button.disabledColor};
          `};

  ${({ fragment }) => fragment && fragment}
`
