import React, { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from 'Widgets/Error'
import ErrorLayout from 'Widgets/Error/ErrorLayout'

type State = {
  error: any
}

type Props = {
  children: ReactNode
}

class CoolErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    if (event.reason.message === 'canceled') return this.props.children
    this.setState({
      error: event.reason,
    })
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error: error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  public render() {
    if (this.state.error) {
      const error = this.state.error
      return (
        <ErrorLayout>
          <ErrorPage error={error} />
        </ErrorLayout>
      )
    } else {
      return this.props.children
    }
  }
}

export default CoolErrorBoundary
