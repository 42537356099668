import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${p => p.theme.colors.blur.main};
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  border-radius: 32px;
  gap: 24px;
  background: ${p => p.theme.colors.blocks.light};
`
export const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  margin-top: 12px;
  width: 100%;
  gap: 8px;
`
export const ReviewMarketplace = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const MarketplaceIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 6px;
`
export const ReviewLinesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`
export const ReviewLine = styled.div<{ background: string; width: string }>`
  border-radius: 8px;
  background: ${p => p.background};
  width: ${p => p.width};
  height: 8px;
`
export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`
export const ReviewTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 8px;
`
