import React, { ReactNode, useState } from 'react'
import NoData from '../../Kit/NoData'
import ChartTooltip from '../../Kit/ChartTooltip'
import Text from '../../Kit/Text'

import { ISentimentOverTimePlotItem } from 'Types/charts'
import { useTranslation } from 'Hooks/useTranslation'
import {
  ChartWrapper,
  FiltersAndTitle,
  FiltersWrapper,
  SentimentItem,
  SentimentItemsWrapper,
} from './styled'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CSSProp } from 'styled-components'

type LineChartProps = {
  data: ISentimentOverTimePlotItem[]
  extraFilters?: ReactNode
  title?: string
  customStyleWrapper?: CSSProp
}
const SentimentLine = ({
  data,
  extraFilters,
  title,
  customStyleWrapper,
}: LineChartProps) => {
  const t = useTranslation()

  const [showPositive, setShowPositive] = useState(true)
  const [showNegative, setShowNegative] = useState(true)
  const [showNeutral, setShowNeutral] = useState(true)

  const transformedData = data.map(item => ({
    name: item.date,
    sentiment_p: item.values.sentiment_p,
    sentiment_n: item.values.sentiment_n,
    sentiment_i: item.values.sentiment_i,
  }))

  return (
    <ChartWrapper customStyleWrapper={customStyleWrapper}>
      <FiltersAndTitle>
        <Text fontSize={20} fontWeight={500}>
          {title ? title : t('SentimentLine.sentimentDynamics')}
        </Text>
        {Boolean(transformedData.length) && (
          <FiltersWrapper>
            {extraFilters}
            <SentimentItemsWrapper>
              <SentimentItem
                borderColor={'#05DB61'}
                background={'#EAFAF3'}
                onClick={() => setShowPositive(!showPositive)}
                isActive={showPositive}
              >
                <Text fontSize={12} fontWeight={400} color={'#05DB61'}>
                  {t('SentimentLine.positive')}
                </Text>
              </SentimentItem>
              <SentimentItem
                borderColor={'#FF4A22'}
                background={'#FFF1F1'}
                onClick={() => setShowNegative(!showNegative)}
                isActive={showNegative}
              >
                <Text fontSize={12} fontWeight={400} color={'#FF4A22'}>
                  {t('SentimentLine.negative')}
                </Text>
              </SentimentItem>
              <SentimentItem
                borderColor={'#2353FF'}
                background={'#EBF3FF'}
                onClick={() => setShowNeutral(!showNeutral)}
                isActive={showNeutral}
              >
                <Text fontSize={12} fontWeight={400} color={'#2353FF'}>
                  {t('SentimentLine.neutral')}
                </Text>
              </SentimentItem>
            </SentimentItemsWrapper>
          </FiltersWrapper>
        )}
      </FiltersAndTitle>
      {transformedData.length ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={transformedData}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<ChartTooltip />} />
            {showPositive && (
              <Line
                type="monotone"
                dataKey="sentiment_p"
                stroke="#05DB61"
                strokeWidth={2}
              />
            )}
            {showNegative && (
              <Line
                type="monotone"
                dataKey="sentiment_n"
                stroke="#FF4A22"
                strokeWidth={2}
              />
            )}
            {showNeutral && (
              <Line
                type="monotone"
                dataKey="sentiment_i"
                stroke="#2353FF"
                strokeWidth={2}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoData isTooltip={false} />
      )}
    </ChartWrapper>
  )
}

export default SentimentLine
