import React from 'react'
import DownloadFile from 'Assets/DownloadFile'
import { Wrapper } from './styled'
import Text from '../Kit/Text'
import { useTranslation } from '../../Hooks/useTranslation'

type DownloadReportProps = {
  onClick: () => void
}

const DownloadReport = ({ onClick }: DownloadReportProps) => {
  const t = useTranslation()
  return (
    <Wrapper onClick={onClick}>
      <DownloadFile />
      <Text fontSize={13} fontWeight={700}>
        {t('DownloadReport.title')}
      </Text>
    </Wrapper>
  )
}

export default DownloadReport
