import React from 'react'
import { Card, CardsWrapper, Wrapper } from './styled'
import Text from 'Components/Kit/Text'
import { css, useTheme } from 'styled-components'
import FirstDecoration from '../../Assets/FirstDecoration'
import SecondDecoration from '../../Assets/SecondDecoration'
import ThirdDecoration from '../../Assets/ThirdDecoration'

const cards = [
  {
    id: 1,
    title: 'Повысить качество продукции',
    description:
      'Понимание того, какие аспекты продукта наиболее ценятся клиентами, и какие вызывают недовольство',
    icon: FirstDecoration,
  },
  {
    id: 2,
    title: 'Оптимизация клиентского опыта',
    description:
      'Идентификация проблем в отзывах улучшает обслуживание и клиентский опыт',
    icon: SecondDecoration,
  },
  {
    id: 3,
    title: 'Прогнозирование трендов и потребностей',
    description:
      'Анализ больших данных выявляет текущие и будущие потребительские тренды, опережая рынок',
    icon: ThirdDecoration,
  },
  {
    id: 4,
    title: 'Стратегическое позиционирование',
    description:
      'Анализ отзывов конкурентов выявляет их слабые стороны, помогая выгодно позиционировать свои товары',
    icon: FirstDecoration,
  },
  {
    id: 5,
    title: 'Управление репутацией',
    description:
      'Быстрая реакция на негативные отзывы поддерживает положительное восприятие бренда',
    icon: SecondDecoration,
  },
  {
    id: 6,
    title: 'Инновационное развитие продуктов',
    description:
      'Инсайты из отзывов вдохновляют на разработку новых или улучшение существующих продуктов',
    icon: ThirdDecoration,
  },
  {
    id: 7,
    title: 'Улучшение маркетинговых стратегий',
    description:
      'Понимание восприятия продуктов клиентами позволяет точнее таргетировать маркетинговые кампании',
    icon: FirstDecoration,
  },
  {
    id: 8,
    title: 'Конкурентное преимущество',
    description:
      'Использование анализа отзывов с помощью LLM позволяет быстрее адаптироваться к рынку и потребностям клиентов, получая преимущество',
    icon: SecondDecoration,
  },
]

const Benefits = () => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Text
        fontSize={40}
        fontWeight={400}
        styledFragment={css`text-align: center; width: 60%`}
      >
        Ещё больше возможностей с тарифами Napoleon IT Отзывы
      </Text>
      <CardsWrapper>
        {cards.map(card => (
          <Card key={card.id}>
            {card.icon()}
            <Text fontSize={26} fontWeight={400}>
              {card.title}
            </Text>
            <Text fontSize={16} fontWeight={400} color={theme.colors.text.gray}>
              {card.description}
            </Text>
          </Card>
        ))}
      </CardsWrapper>
    </Wrapper>
  )
}

export default Benefits
