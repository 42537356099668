import React from 'react'
import { Wrapper, SentimentItem } from './styled'
import { useTheme } from 'styled-components'

type SimpleSentimentChartProps = {
  data: { p: number; i: number; n: number }
}

const SentimentSimpleChart = ({ data }: SimpleSentimentChartProps) => {
  const theme = useTheme()
  const sum = Object.values(data).reduce((acc, value) => acc + value, 0)

  return (
    <Wrapper>
      {data.p > 0 && (
        <SentimentItem
          background={theme.colors.charts.green}
          width={(data.p / sum) * 100}
        />
      )}
      {data.n > 0 && (
        <SentimentItem
          background={theme.colors.charts.red}
          width={(data.n / sum) * 100}
        />
      )}
      {data.i > 0 && (
        <SentimentItem
          background={theme.colors.charts.blue}
          width={(data.i / sum) * 100}
        />
      )}
    </Wrapper>
  )
}

export default SentimentSimpleChart
