import React from 'react'
import { IconType } from 'Types/icon'

const Star = (props: IconType) => {
  const { fill = '#FFDB1D', height = 14, viewBox = '0 0 14 14', width = 14 } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67637 3.18081C7.85891 1.06027 8.44971 0 9.33358 0C10.2175 0 10.8083 1.06027 11.9908 3.18081L12.2969 3.72961C12.6329 4.33255 12.8009 4.63401 13.0623 4.83282C13.3236 5.03162 13.6503 5.10535 14.3036 5.25282L14.8972 5.38722C17.1932 5.90709 18.3403 6.16655 18.6137 7.04482C18.8863 7.92216 18.1041 8.83776 16.5389 10.668L16.1339 11.1412C15.6896 11.6611 15.4665 11.9215 15.3667 12.2426C15.2668 12.5646 15.3004 12.9118 15.3676 13.6052L15.4292 14.2371C15.6653 16.6797 15.7839 17.9005 15.0689 18.4427C14.354 18.9859 13.2788 18.4903 11.1303 17.501L10.5731 17.2453C9.96265 16.9634 9.65745 16.8234 9.33358 16.8234C9.00971 16.8234 8.70451 16.9634 8.09411 17.2453L7.53784 17.501C5.38837 18.4903 4.31317 18.985 3.59916 18.4437C2.88329 17.9005 3.00183 16.6797 3.23796 14.2371L3.29956 13.6062C3.36676 12.9118 3.40036 12.5646 3.29956 12.2435C3.20063 11.9215 2.97756 11.6611 2.53329 11.1422L2.12823 10.668C0.563021 8.83869 -0.219115 7.92309 0.0534195 7.04482C0.326887 6.16655 1.47489 5.90615 3.7709 5.38722L4.3645 5.25282C5.0169 5.10535 5.34264 5.03162 5.6049 4.83282C5.86624 4.63401 6.03424 4.33255 6.37024 3.72961L6.67637 3.18081Z"
        fill={fill}
      />
    </svg>
  )
}

export default Star
