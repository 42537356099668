import { StyledVars } from 'Stylesheets/types'

export const light: StyledVars = {
  name: 'light-theme',
  colors: {
    background: '#ECEFF9',
    text: {
      main: '#000000',
      blue: '#2353FF',
      gray: '#ABADB6',
      light: '#ffffff',
      green: '#02C255',
      red: '#FF4A22',
    },
    input: {
      placeholder: '#98999F',
      text: '#000000',
      background: '#ffffff',
      activeBorder: '#2353FF',
      errorColor: '#FF4A22',
      errorBorder: '#FF4A22',
    },
    button: {
      background: '#2353FF',
      disabled: '#DADCE7',
      hover: '#1D49DD',
      disabledColor: '#ffffff',
      color: '#ffffff',
      secondaryBackground: '#ffffff',
      secondaryColor: '#000',
      secondaryHover: '#F4F6FB',
    },
    logo: {
      fill: '#000000',
    },
    blocks: {
      light: '#ffffff',
      dark: '#000000',
      blue: '#2353FF',
      gray: '#F0F2F7',
      lightGray: '#F4F6FB',
      red: '#FF4A22',
    },
    icons: {
      dark: '#000000',
      light: '#ffffff',
      green: '#02C255',
      gray: '#D1D3E0',
      red: '#FF4A22',
      blue: '#2353FF',
    },
    charts: {
      red: '#FF4A22',
      green: '#05DB61',
      blue: '#2353FF',
    },
    lines: {
      green: '#05DB61',
      lightGreen: '#A3DB05',
      yellow: '#FFDB1D',
      orange: '#FF7C1D',
      red: '#FF4A22',
    },
    tags: {
      green: {
        text: '#02C255',
        background: '#EAFAF3',
      },
      red: {
        text: '#FF4A22',
        background: '#FFECF0',
      },
      gray: {
        text: '#000000',
        background: '#F0F2F7',
      },
      blue: {
        text: '#2353FF',
        background: '#E5EAF3',
      },
    },
    blur: {
      main: 'rgba(2, 0, 111, 0.09)',
    },
  },
}
