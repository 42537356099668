import React, { ReactNode } from 'react'
import Text from 'Components/Kit/Text'
import { FiltersWrapper, Wrapper } from './styled'
import Filters from 'Components/Filters'
import { useTranslation } from 'Hooks/useTranslation'
import { IMarketplace } from 'Types/marketplace'

type SubHeaderProps = {
  title: string
  marketplaces: IMarketplace[]
  handleActiveMarketplaceChange: (marketplace: string) => void
  extraActions?: ReactNode | ReactNode[]
}
const SubHeaderWithFilters = ({
  title,
  marketplaces,
  handleActiveMarketplaceChange,
  extraActions,
}: SubHeaderProps) => {
  return (
    <Wrapper>
      <Text fontSize={36} fontWeight={500}>
        {title}
      </Text>
      <FiltersWrapper>
        <Filters
          marketplaces={marketplaces}
          handleActiveMarketplaceChange={handleActiveMarketplaceChange}
        />
        {extraActions}
      </FiltersWrapper>
    </Wrapper>
  )
}

export default SubHeaderWithFilters
