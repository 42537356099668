import React from 'react'
import { IconType } from 'Types/icon'

const MockItemImage = (props: IconType) => {
  const { width = 180, height = 180 } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0449 0.666672C17.2082 0.666672 19.3333 2.88624 19.3333 6.18894V13.8111C19.3333 17.1138 17.2082 19.3333 14.044 19.3333H5.9551C2.79177 19.3333 0.666666 17.1138 0.666666 13.8111V6.18894C0.666666 2.88624 2.79177 0.666672 5.9551 0.666672H14.0449ZM15.0742 10.5134C14.0737 9.88924 13.3013 10.7657 13.0929 11.046C12.8921 11.3167 12.7194 11.6153 12.5373 11.9139C12.0924 12.6507 11.5828 13.5002 10.7006 13.9944C9.41852 14.7042 8.44526 14.0502 7.74511 13.5744C7.48232 13.3972 7.22703 13.2296 6.97269 13.1179C6.34575 12.8472 5.78168 13.1554 4.94451 14.2188C4.50527 14.7745 4.06979 15.3255 3.62868 15.8745C3.36495 16.203 3.42783 16.7096 3.78354 16.9292C4.35135 17.2789 5.04399 17.4667 5.82673 17.4667H13.6926C14.1366 17.4667 14.5814 17.406 15.0056 17.2673C15.9611 16.9552 16.7194 16.2405 17.1155 15.2966C17.4496 14.5029 17.612 13.5397 17.2994 12.7384C17.1952 12.4725 17.0395 12.225 16.8208 12.0073C16.2473 11.4381 15.7114 10.9064 15.0742 10.5134ZM6.73227 4.40001C5.44553 4.40001 4.4 5.44695 4.4 6.73334C4.4 8.01972 5.44553 9.06667 6.73227 9.06667C8.01806 9.06667 9.06454 8.01972 9.06454 6.73334C9.06454 5.44695 8.01806 4.40001 6.73227 4.40001Z"
        fill="#E4E6F1"
      />
    </svg>
  )
}

export default MockItemImage
