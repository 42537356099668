import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  width: 235px;
  background: ${p => p.theme.colors.blocks?.light};
  border-radius: 24px;
`
export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const RatingValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`

export const LinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`

export const Line = styled.div`
  position: relative;
  width: 116px;
  height: 8px;
  border-radius: 100px;
  background: ${p => p.theme.colors.blocks?.gray};
`

export const ActiveLine = styled.div<{ width: number; background: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 100px;
  max-width: 116px;
  width: ${p => p.width}%;
  background: ${p => p.background};
`
