import React from 'react'
import { Navigate, useOutlet } from 'react-router-dom'
import { PrivateRoutes } from './types'
import { useUserStore } from 'Store/useUserStore'

const SubscriptionRoute = () => {
  const { isUserHasSubscription, user } = useUserStore()
  const outlet = useOutlet()

  return user && !isUserHasSubscription ? (
    <Navigate to={PrivateRoutes.SUBSCRIPTION} replace />
  ) : (
    outlet
  )
}

export default SubscriptionRoute
