import React, { ReactNode, useEffect, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  MarketplaceIcon,
  Overlay,
  RatingWrapper,
  ReviewLine,
  ReviewLinesWrapper,
  ReviewMarketplace,
  ReviewTitle,
  ReviewWrapper,
  Wrapper,
} from './styled'
import Text from '../Kit/Text'
import { useTheme } from 'styled-components'
import { getMarketplaceIcon } from 'Utils/marketplace'
import Star from 'Assets/Star'
import Button from 'Components/Kit/Button'
import { useNavigate } from 'react-router-dom'

type Segment = {
  width: string
  color: string
}

const marketplaces = ['amazon', 'google maps', 'googleplay', 'appstore', 'allegro']

const AnalyzeModal = NiceModal.create(() => {
  const theme = useTheme()
  const navigate = useNavigate()
  const modal = useModal()
  const [lines, setLines] = useState<ReactNode[]>([])
  const [marketplace, setMarketplace] = useState('')
  const [rating, setRating] = useState(0)
  const getRandomColor = () => {
    const colors = [
      theme.colors.charts.red,
      theme.colors.charts.blue,
      theme.colors.charts.green,
    ]
    const randomIndex = Math.floor(Math.random() * colors.length)
    return colors[randomIndex]
  }
  const getRandomMarketplace = () => {
    const randomIndex = Math.floor(Math.random() * marketplaces.length)
    return marketplaces[randomIndex]
  }
  const getRandomRating = () => {
    return parseFloat((Math.random() * 4 + 1).toFixed(1))
  }
  const generateMockLines = (): Segment[][] => {
    const linesCount = 3
    const maxSegments = 10
    const generatedLines: Segment[][] = []

    for (let i = 0; i < linesCount; i++) {
      const segments: Segment[] = []
      for (let j = 0; j < maxSegments; j++) {
        const width = `${Math.floor(Math.random() * 10) + 5}%`
        segments.push({
          width,
          color: theme.colors.blocks.gray,
        })
      }
      generatedLines.push(segments)
    }

    return generatedLines
  }
  const generateColoredLines = () => {
    const lines = generateMockLines()
    const colorCount = Math.floor(Math.random() * 2) + 3

    lines.forEach(line => {
      const coloredSegments: number[] = []
      while (coloredSegments.length < colorCount) {
        const colorIndex = Math.floor(Math.random() * line.length)
        if (!coloredSegments.includes(colorIndex)) {
          line[colorIndex].color = getRandomColor()
          coloredSegments.push(colorIndex)
        }
      }
    })

    setLines(
      lines.map((line, lineIndex) => (
        <ReviewLinesWrapper key={lineIndex}>
          {line.map((segment, segmentIndex) => (
            <ReviewLine
              key={segmentIndex}
              background={segment.color}
              width={segment.width}
            />
          ))}
        </ReviewLinesWrapper>
      )),
    )
  }
  const handleModalClose = () => {
    modal.remove()
    navigate(-1)
  }

  useEffect(() => {
    generateColoredLines()
    setMarketplace(getRandomMarketplace())
    setRating(getRandomRating())

    const interval = setInterval(() => {
      generateColoredLines()
      setMarketplace(getRandomMarketplace())
      setRating(getRandomRating())
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Overlay>
      <Wrapper>
        <Text fontSize={20} fontWeight={500}>
          Отзывы анализируются, пожалуйста подождите
        </Text>
        <ReviewWrapper>
          <ReviewTitle>
            <ReviewMarketplace>
              <MarketplaceIcon src={getMarketplaceIcon(marketplace)} />
              <Text fontSize={12} fontWeight={400} color={theme.colors.text.gray}>
                {marketplace}
              </Text>
            </ReviewMarketplace>
            <RatingWrapper>
              <Star width={14} height={14} viewBox={'0 0 20 20'} />
              <Text fontSize={12} fontWeight={500}>
                {rating}
              </Text>
            </RatingWrapper>
          </ReviewTitle>
          <>{lines}</>
        </ReviewWrapper>
        <Button onClick={handleModalClose}>Назад</Button>
      </Wrapper>
    </Overlay>
  )
})

export default AnalyzeModal
