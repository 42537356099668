import React, { useState } from 'react'
import { Wrapper } from './styled'
import { useTranslation } from 'Hooks/useTranslation'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import GenericModal from 'Components/Kit/GenericModal'
import ChooseCategory from './Components/ChooseCategory'
import AddSources from './Components/AddSources'
import AddTopics from './Components/AddTopics'
import { TreeType } from 'Types/trees'
import { ActiveStep, NewItem } from './types'

const AddItem = NiceModal.create(() => {
  const modal = useModal()
  const t = useTranslation()

  const [activeStep, setActiveStep] = useState<ActiveStep>(ActiveStep.CATEGORIES)
  const [items, setItems] = useState<NewItem[]>([
    { link: '', since_date: null, parse_review_count: 100 },
  ])
  const [treeType, setTreeType] = useState<TreeType>({ place: '', type: '' })
  const renderStep = () => {
    switch (activeStep) {
      case ActiveStep.CATEGORIES:
        return (
          <ChooseCategory
            handleNextStep={() => setActiveStep(ActiveStep.SOURCES)}
            treeType={treeType}
            setTreeType={setTreeType}
          />
        )
      case ActiveStep.SOURCES:
        return (
          <AddSources
            handleActiveStep={setActiveStep}
            setItems={setItems}
            items={items}
          />
        )
      case ActiveStep.TOPICS:
        return (
          <AddTopics
            handleActiveStep={setActiveStep}
            items={items}
            treeType={treeType}
            closeModal={() => {
              modal.resolve()
              modal.remove()
            }}
          />
        )
      default:
        return (
          <ChooseCategory
            handleNextStep={() => setActiveStep(ActiveStep.SOURCES)}
            treeType={treeType}
            setTreeType={setTreeType}
          />
        )
    }
  }

  return (
    <GenericModal title={t('AddItem.title')} onClose={() => modal.remove()}>
      <Wrapper>{renderStep()}</Wrapper>
    </GenericModal>
  )
})
export default AddItem
