import React, { useEffect } from 'react'
import { Wrapper } from './styled'
import Text from 'Components/Kit/Text'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useTheme } from 'styled-components'

type NotificationProps = {
  message: string
}

const Notification = NiceModal.create(({ message }: NotificationProps) => {
  const theme = useTheme()
  const modal = useModal()

  useEffect(() => {
    const timer = setTimeout(() => {
      modal.hide().then(() => {
        modal.remove()
      })
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Wrapper>
      <Text fontSize={14} fontWeight={500} color={theme.colors.text.light}>
        {message}
      </Text>
    </Wrapper>
  )
})

export default Notification
