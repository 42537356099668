import React from 'react'
import { Navigate, useOutlet } from 'react-router-dom'
import { PrivateRoutes } from 'Router/types'
import { useUserStore } from 'Store/useUserStore'

const NotProtectedRoute = () => {
  const { user } = useUserStore()
  const outlet = useOutlet()

  return user ? <Navigate to={PrivateRoutes.OVERVIEW} replace /> : outlet
}

export default NotProtectedRoute
