import React, { useEffect, useState } from 'react'
import { useUserStore } from 'Store/useUserStore'
import { useRequest } from 'Hooks/useRequest'
import { AuthResponseType, YandexOauthType } from 'Types/yandexOauth'

const Yandex = () => {
  const { setJWTToken, setUserEmail } = useUserStore()
  const [isSdkLoaded, setIsSdkLoaded] = useState(false)

  const { requestData: loginUser, data: loginUserResponse } =
    useRequest<AuthResponseType>()
  const handleLoginUser = async (token: string) => {
    await loginUser('POST', 'oauth/yandex/', { access_token: token })
  }

  useEffect(() => {
    const script1 = document.createElement('script')
    script1.src =
      'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js'
    script1.addEventListener('load', () => setIsSdkLoaded(true))
    document.body.appendChild(script1)
  }, [])

  useEffect(() => {
    if (isSdkLoaded && window.YaAuthSuggest) {
      window.YaAuthSuggest.init(
        {
          client_id: 'aa3c7ae5f246410e91b70d003e3486f3',
          response_type: 'token',
          redirect_uri: 'https://dev.reviews.napoleonit.ru/oauth/yandex/',
        },
        'https://dev.reviews.napoleonit.ru/',
        {
          view: 'button',
          parentId: 'buttonContainerId',
          buttonSize: 'm',
          buttonView: 'main',
          buttonTheme: 'light',
          buttonBorderRadius: '12',
          buttonIcon: 'ya',
        },
      )
        .then((result: any) => result.handler())
        .then(async (data: YandexOauthType) => {
          await handleLoginUser(data.access_token)
        })
        .catch((error: any) => {
          console.log('Что-то пошло не так: ', error)
        })
    }
  }, [isSdkLoaded])

  useEffect(() => {
    if (!loginUserResponse) return
    setJWTToken(loginUserResponse.access_token)
    setUserEmail(loginUserResponse.email)
  }, [loginUserResponse])

  return <div id="buttonContainerId" style={{ width: '100%' }}></div>
}

export default Yandex
