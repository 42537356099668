import React, { useRef, useEffect, useState } from 'react'
import { Wrapper, Selector, Button } from './styled'
import dayjs from 'dayjs'
import { useTranslation } from 'Hooks/useTranslation'
import { useFiltersStore } from '../../../Store/useFiltersStore'

type SimpleDatePickerProps = {
  width: number
}

const SimpleDatePicker = (props: SimpleDatePickerProps) => {
  const { width } = props
  const t = useTranslation()
  const { setActivePeriod, activePeriodType, setActivePeriodType } = useFiltersStore()

  const [selectorStyle, setSelectorStyle] = useState({ width: 0, left: 0 })
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([])

  const buttons = [
    t('SimpleDatePicker.lastWeek'),
    t('SimpleDatePicker.lastMonth'),
    t('SimpleDatePicker.allTime'),
  ]

  useEffect(() => {
    const activeButton = buttonsRef.current[activePeriodType]
    if (activeButton) {
      const { offsetWidth, offsetLeft } = activeButton
      setSelectorStyle({
        width: offsetWidth,
        left: offsetLeft,
      })
    }
  }, [activePeriodType, width])
  const getTimePeriod = (selectedButton: number) => {
    switch (selectedButton) {
      case 0:
        return dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      case 1:
        return dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      case 2:
        return ''
      default:
        return dayjs().subtract(1, 'week').format('YYYY-MM-DD')
    }
  }
  const onButtonClick = (index: number) => {
    const endDate = index === 2 ? '' : dayjs().format('YYYY-MM-DD')
    const startDate = getTimePeriod(index)
    setActivePeriod(startDate, endDate)
    setActivePeriodType(index)
  }

  return (
    <Wrapper width={width}>
      <Selector width={selectorStyle.width} left={selectorStyle.left} />
      {buttons.map((buttonTitle, index) => {
        return (
          <Button
            key={buttonTitle}
            ref={el => (buttonsRef.current[index] = el)}
            type="button"
            value={index}
            onClick={() => onButtonClick(index)}
            className={`${activePeriodType === index && 'selected'}`}
          >
            {buttonTitle}
          </Button>
        )
      })}
    </Wrapper>
  )
}

export default SimpleDatePicker
