import React, { useEffect } from 'react'
import { FormWrapper, InputsWrapper } from './styled'
import Input from 'Components/Kit/Input'
import { useValidationState, validationFuncs } from 'Hooks/useValidatedState'
import Button from 'Components/Kit/Button'
import Loader from 'Components/Kit/Loader'
import { useTheme } from 'styled-components'
import { useUserStore } from 'Store/useUserStore'
import { useRequest } from 'Hooks/useRequest'
import { useFetch } from '../../../../Hooks/useFetch'
import { IUser } from '../../../../Types/user'

const Login = () => {
  const { setJWTToken } = useUserStore()
  const theme = useTheme()

  const [[email, setEmail], isEmailValid] = useValidationState<string>(
    '',
    validationFuncs.hasValue,
  )
  const [[password, setPassword], isPasswordValid] = useValidationState<string>(
    '',
    validationFuncs.validPassword,
  )

  const {
    requestData: loginUser,
    isLoading: isLoginUserLoading,
    data: loginUserResponse,
  } = useRequest<{ access: string; refresh: string }>()

  const onUserLogin = async () => {
    await loginUser('POST', 'auth/jwt/create/', {
      username: email,
      password: password,
    })
  }

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isPasswordValid && isEmailValid) {
        onUserLogin()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isEmailValid, isPasswordValid])

  useEffect(() => {
    if (!loginUserResponse) return
    setJWTToken(loginUserResponse.access)
  }, [loginUserResponse])

  return (
    <FormWrapper>
      <InputsWrapper>
        <Input
          onChange={setEmail}
          value={email}
          placeholder={'Логин'}
          isValueValid={isEmailValid}
          invalidMessage={'Обязательное поле'}
          inputSize={'large'}
        />
        <Input
          onChange={setPassword}
          value={password}
          placeholder={'Пароль'}
          type={'password'}
          isValueValid={isPasswordValid}
          invalidMessage={'Минимальное количество символов - 4'}
          inputSize={'large'}
        />
        <Button
          onClick={onUserLogin}
          buttonSize={'large'}
          disabled={!(isEmailValid && isPasswordValid)}
        >
          {isLoginUserLoading ? (
            <Loader size={20} color={theme.colors.icons.light} />
          ) : (
            'Войти'
          )}
        </Button>
      </InputsWrapper>
    </FormWrapper>
  )
}

export default Login
