import styled from 'styled-components'
export const StyledButtonsGrid = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 10px 17px;
  justify-content: center;
  margin-bottom: 82px;


  @media screen and (max-width: 500px) {
    margin-bottom: 82px;
    flex-wrap: wrap;
    padding: 0 20px;
  }
`
