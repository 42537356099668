import React, { ReactNode } from 'react'
import { Tooltip as AntdTooltip } from 'antd'
import styled, { css } from 'styled-components'

type TooltipProps = {
  title: ReactNode | string
  children: ReactNode
  variant?: 'small' | 'large'
}

const StyledTooltip = styled.div<{ variant: 'small' | 'large' }>`
  .ant-tooltip-inner {
    padding: 16px 20px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
    color: ${p => p.theme.colors.text.main};
    background: ${p => p.theme.colors.blocks?.light};

    ${({ variant }) =>
      variant === 'small' &&
      css`
              padding: 4px 8px;
              border-radius: 8px;
              line-height: 12.5px;
            `};
  }
`
const CustomTooltip = (props: TooltipProps) => {
  const { variant = 'large', children, title } = props
  return (
    <StyledTooltip variant={variant}>
      <AntdTooltip
        getPopupContainer={triggerNode => triggerNode}
        title={title}
        arrow={false}
        placement="bottom"
        style={{ padding: '16px 20px' }}
      >
        <span>{children}</span>
      </AntdTooltip>
    </StyledTooltip>
  )
}

export default CustomTooltip
