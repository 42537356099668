import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 235px;
  height: 160px;
  background: ${p => p.theme.colors.blocks?.light};
  border-radius: 24px;
`
export const StarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px
`
export const TextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`
export const InfoIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`
