import { ReviewsSentiment } from '../../../Types/product'
import Text from '../Text'
import SentimentChartTooltip from '../../Charts/SentimentChartTooltip'
import React from 'react'
import { Wrapper } from './styled'

const ChartTooltip = ({
  payload,
  label,
  active,
}: {
  payload?: any
  label?: string
  active?: boolean
}) => {
  if (active && payload && payload[0]) {
    const { sentiment_p, sentiment_n, sentiment_i } = payload[0].payload
    const sentiments: ReviewsSentiment = {
      p: sentiment_p,
      n: sentiment_n,
      i: sentiment_i,
    }
    return (
      <Wrapper>
        <Text fontSize={12} fontWeight={500} color={'#000'}>
          {label}
        </Text>
        <SentimentChartTooltip sentiments={sentiments} type={'dark'} />
      </Wrapper>
    )
  }

  return null
}

export default ChartTooltip
