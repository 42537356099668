import { AxiosInstance, AxiosResponse } from 'axios'
import instance from 'API/instance'
import { IGroup } from 'Types/category'

function overviewModule(instance: AxiosInstance) {
  return {
    createCategory(name: string): Promise<AxiosResponse<IGroup>> {
      return instance.post(`categories/`, { name })
    },
  }
}

export const overviewRequests = Object.assign(instance, overviewModule(instance))
