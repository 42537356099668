import React from 'react'
import { InfoIcon, TextsWrapper, Wrapper } from './styled'
import CircleProgressBar from 'Assets/CircleProgressBar'
import Text from 'Components/Kit/Text'
import { useTheme } from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'
import Info from 'Assets/Info'
import Tooltip from 'Components/Kit/Tooltip'
import Tag from '../../Kit/Tag'
import NoData from '../../Kit/NoData'

type AverageSentimentProps = {
  percentage: number
  difference: number
}

const AverageSentiment = ({ percentage, difference }: AverageSentimentProps) => {
  const theme = useTheme()
  const t = useTranslation()
  const differencePercentage = +(Math.abs(difference) * 100).toFixed(0)
  const getColorsAndText = (percentage: number) => {
    if (percentage <= 30)
      return {
        textColor: theme.colors.text.red,
        mainStroke: theme.colors.text.red,
        secondaryStroke: '#F3E9E5',
        text: t('AvgSentiment.bad'),
      }
    if (percentage > 30 && percentage <= 70)
      return {
        textColor: theme.colors.text.blue,
        mainStroke: theme.colors.text.blue,
        secondaryStroke: '#E5EAF3',
        text: t('AvgSentiment.normal'),
      }
    return {
      textColor: theme.colors.text.green,
      mainStroke: theme.colors.text.green,
      secondaryStroke: '#E7F3E5',
      text: t('AvgSentiment.good'),
    }
  }

  return (
    <Wrapper>
      <Tooltip title={t('AvgSentiment.tooltipText')}>
        <InfoIcon>
          <Info />
        </InfoIcon>
      </Tooltip>
      {percentage !== 0 ? (
        <>
          <CircleProgressBar
            percentage={percentage}
            mainStroke={getColorsAndText(percentage).mainStroke}
            secondaryStroke={getColorsAndText(percentage).secondaryStroke}
          />
          <TextsWrapper>
            {differencePercentage != 0 && (
              <Tag
                content={`${differencePercentage}%`}
                withIcon
                view={difference >= 0 ? 'green' : 'red'}
              />
            )}
            <Text fontSize={14} fontWeight={400}>
              {t('AvgSentiment.title')}
            </Text>
            <Text
              fontSize={14}
              fontWeight={700}
              color={getColorsAndText(percentage).textColor}
            >
              {getColorsAndText(percentage).text}
            </Text>
          </TextsWrapper>
        </>
      ) : (
        <NoData isTooltip={false} />
      )}
    </Wrapper>
  )
}

export default AverageSentiment
