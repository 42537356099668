import styled from 'styled-components'

export const TopicsAndSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;`
export const TopicsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 28px 32px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks.light};
  height: 600px;
  width: 350px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
export const TopicSearchWrapper = styled.div`
  width: 350px;
`
export const TopicWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  border-top: 1px solid ${p => p.theme.colors.blocks.gray};
  border-bottom: 1px solid ${p => p.theme.colors.blocks.gray};
  padding: 12px 0;
`
