import React from 'react'
import { Wrapper, ItemsWrapper, ItemImg, NoItemImg } from './styled'
import MockItemImage from 'Assets/MockItemImage'
import Text from 'Components/Kit/Text'
import { useTheme } from 'styled-components'
import SingleArrow from 'Assets/SingleArrow'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'Hooks/useTranslation'
import useSelectedItemsStore from '../../Store/useSelectedItemsStore'

type ComparisonNavigationProps = {
  isActive: boolean
}

const ComparisonNav = ({ isActive }: ComparisonNavigationProps) => {
  const { selectedItems } = useSelectedItemsStore()
  const theme = useTheme()
  const t = useTranslation()
  const navigate = useNavigate()

  const goToComparison = () => {
    navigate('/comparison', { replace: true })
  }

  return (
    <Wrapper onClick={goToComparison} isActive={isActive}>
      <ItemsWrapper>
        {selectedItems.map(item =>
          item.details.imgUrl ? (
            <ItemImg src={item.details.imgUrl} key={item.id} />
          ) : (
            <NoItemImg key={item.id}>
              <MockItemImage height={10} width={10} />
            </NoItemImg>
          ),
        )}
      </ItemsWrapper>
      <Text fontSize={14} fontWeight={500} color={theme.colors.text.light}>
        {t('ComparisonNav.title')}: {selectedItems.length}
      </Text>
      <SingleArrow fill={theme.colors.icons.green} rotate={-90} height={10} width={8} />
    </Wrapper>
  )
}

export default ComparisonNav
