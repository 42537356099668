import React from 'react'
import { CustomText } from './styled'
import { FlattenSimpleInterpolation, useTheme } from 'styled-components'
interface TextProps {
  color?: string
  fontSize: number
  fontWeight: number
  children: React.ReactNode | React.ReactNode[]
  styledFragment?: FlattenSimpleInterpolation
  onClick?: () => void
}

const Text = (props: TextProps) => {
  const theme = useTheme()

  const {
    color = theme.colors.text.main,
    fontSize,
    fontWeight,
    children,
    styledFragment,
    onClick,
  } = props

  return (
    <CustomText
      onClick={onClick}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      styledFragment={styledFragment}
    >
      {children}
    </CustomText>
  )
}

export default Text
