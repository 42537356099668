import React from 'react'
import { StyledSpinner } from './styled'
import { useTheme } from 'styled-components'

type LoaderProps = {
  animationDuration?: number
  size?: number
  color?: string
}

const Loader = (props: LoaderProps) => {
  const theme = useTheme()
  const { color = theme.colors.icons.dark, animationDuration, size } = props
  return (
    <StyledSpinner viewBox="0 0 50 50" color={color}>
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </StyledSpinner>
  )
}
export default Loader
