import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 23px 40px 40px 40px;
  background: ${p => p.theme.colors.background};
`
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${p => p.theme.colors.background};

  @media screen and (min-width: 1470px) {
    max-width: 1360px;
  }
`
export const LogoWrapper = styled.div`
  cursor: pointer;
`
export const AccountInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 16px;
`
export const AccountIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 10px;
  background: ${p => p.theme.colors.blocks?.light};
  cursor: pointer;
`
export const AccountTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`
export const PanelWrapper = styled.div<{ isActive: boolean }>`
  position: absolute;
  z-index: 100;
  display: ${p => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  background: ${p => p.theme.colors.blocks?.light};
  right: 0;
  top: 60px;
  width: 245px;
`
export const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  transition: all .3s ease;

  &:hover {
    background: ${p => p.theme.colors.blocks?.lightGray};
  }
`
export const ReviewsAnalyzed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  padding: 8px;
  width: 100%;
`
export const ReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`
export const Line = styled.div`
  position: relative;
  width: 140px;
  height: 8px;
  border-radius: 10px;
  background: ${p => p.theme.colors.blocks?.lightGray};
`

export const ActiveLine = styled.div<{ width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 140px;
  height: 8px;
  border-radius: 10px;
  background: ${p => p.theme.colors.blocks?.blue};
  width: ${p => p.width}%;
`
