import React, { ReactNode } from 'react'
import { CSSProp } from 'styled-components'
import { StyledButton } from './styled'

type ButtonProps = {
  onClick: () => void
  buttonType?: 'main' | 'secondary' | 'bordered'
  buttonSize?: 'large' | 'small'
  disabled?: boolean
  fragment?: CSSProp
  rightIconSrc?: ReactNode | string
  leftIconSrc?: ReactNode | string
  children: string | ReactNode
}

const Button = (props: ButtonProps) => {
  const {
    onClick,
    buttonSize = 'large',
    leftIconSrc,
    rightIconSrc,
    disabled,
    fragment,
    children,
    buttonType = 'main',
  } = props

  return (
    <StyledButton
      onClick={onClick}
      buttonSize={buttonSize}
      disabled={disabled}
      fragment={fragment}
      buttonType={buttonType}
    >
      {leftIconSrc}
      {children}
      {rightIconSrc}
    </StyledButton>
  )
}

export default Button
