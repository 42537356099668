export default {
  'Item.addSource': 'Add source',
  'Item.averageRating': 'Average rating',
  'Item.basedOnReviews': 'based on {count} reviews',
  'Item.reviews': 'Reviews',
  'Item.sentimentDynamics': 'Sentiment dynamics',
  'Item.report': 'Report',
  'Item.positive': 'Positive',
  'Item.negative': 'Negative',
  'Item.topicsFilter': 'All topics',
}
