export default {
  'Item.addSource': 'Добавить источник',
  'Item.averageRating': 'Сред. рейтинг',
  'Item.basedOnReviews': 'на основе {count} отзывов',
  'Item.reviews': 'Отзывы',
  'Item.sentimentDynamics': 'Динамика тональности',
  'Item.report': 'Отчет',
  'Item.positive': 'Позитивные',
  'Item.negative': 'Негативные',
  'Item.topicsFilter': 'Все топики',
}
