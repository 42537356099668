import React from 'react'
import { Wrapper, TitleAndFiltersWrapper } from './styled'
import ReviewsList from 'Components/ReviewsList'
import { IReview, ITopicReview } from 'Types/review'
import ReviewsFilter from 'Components/ReviewsFilter'
import { ITopic } from 'Types/topic'
import Text from 'Components/Kit/Text'
import { useTranslation } from 'Hooks/useTranslation'

type ReviewsProps = {
  reviews: ITopicReview[]
  topics: ITopic[]
  activeReviewsType: number
  setActiveReviewsType: (value: number) => void
  setActiveReviewsTopic: (value: string) => void
}

const Reviews = ({
  reviews,
  topics,
  activeReviewsType,
  setActiveReviewsType,
  setActiveReviewsTopic,
}: ReviewsProps) => {
  const t = useTranslation()
  return (
    <Wrapper>
      <TitleAndFiltersWrapper>
        <Text fontSize={20} fontWeight={500}>
          {t('Item.reviews')}
        </Text>
        <ReviewsFilter
          topics={topics}
          activeType={activeReviewsType}
          setActiveType={setActiveReviewsType}
          setActiveReviewsTopic={setActiveReviewsTopic}
        />
      </TitleAndFiltersWrapper>
      <ReviewsList reviews={reviews} />
    </Wrapper>
  )
}

export default Reviews
