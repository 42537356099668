import React from 'react'
import Text from 'Components/Kit/Text'
import { Tooltip } from 'antd'
import styled, { css, useTheme } from 'styled-components'
import { useTranslation } from 'Hooks/useTranslation'

type NoDataProps = {
  isTooltip?: boolean
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
const NoData = ({ isTooltip = true }: NoDataProps) => {
  const t = useTranslation()
  const theme = useTheme()
  return isTooltip ? (
    <Tooltip title={t('NoData.title')}>
      <span>
        <Text fontSize={14} fontWeight={400} styledFragment={css`cursor: pointer`}>
          -
        </Text>
      </span>
    </Tooltip>
  ) : (
    <Wrapper>
      <Text fontSize={15} fontWeight={400} color={theme.colors.text.gray}>
        {t('NoData.title')}
      </Text>
    </Wrapper>
  )
}

export default NoData
