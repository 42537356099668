export default {
  'Overview.title': 'Общая статистика',
  'Overview.add': 'Добавить',
  'Overview.category': 'Категория',
  'Overview.avgRating': 'Сред. рейтинг',
  'Overview.totalReviews': 'Всего отзывов',
  'Overview.toneOfReviews': 'Тональность',
  'Overview.multiple': 'Несколько',
  'Overview.products': 'Продуктов',
  'Overview.group': 'Группа',
  'Overview.items': 'Продуктов',
  'Overview.sentimentDynamics': 'Динамика тональности',
  'Overview.search': 'Поиск',
  'Overview.createGroup': 'Создать группу',
  'Overview.name': 'Имя',
  'Overview.requiredFill': 'Обязательное поле',
  'Overview.addItem': 'Добавить продукт',
  'Overview.clearAll': 'Очистить',
  'Overview.addToGroup': 'Добавить в группу',
  'Overview.chooseGroup': 'Выберите группу',
  'Overview.newGroup': 'Новая группа',
  'Overview.compare': 'Сравнить',
  'Overview.specifyYourRequest': 'Уточните ваш запрос',
  'Overview.specifyYourRequestDescription':
    'Укажите дату начала аналитики или количество отзывов. Вы также можете добавить оба параметра.',
  'Overview.noItems': 'Вы не добавили ни одного продукта',
  'Overview.close': 'Закрыть',
  'Overview.delete': 'Удалить',
  'Overview.deleteItems': 'Удалить продукты',
  'Overview.deleteItemsQuestion': 'Вы уверены, что хотите удалить продукты?',
  'Overview.noResults': 'По данному запросу ничего не найдено',
  'Overview.source': 'Источник',
}
