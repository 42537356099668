import React from 'react'
import { DatePicker, DatePickerProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import './index.css'
interface CustomDatePickerProps {
  value: DatePickerProps['value']
  onChange: DatePickerProps['onChange']
  mode?: 'date' | 'range'
  placeholder?: string
}

const BetweenDatePicker = (props: CustomDatePickerProps) => {
  const { value, mode, onChange, placeholder } = props

  const disabledFutureDates = (current: Dayjs | null) => {
    if (!current) return false
    return current.isAfter(dayjs().endOf('day'))
  }

  return (
    <DatePicker
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      picker={mode === 'range' ? 'date' : undefined}
      disabledDate={disabledFutureDates}
      size={'large'}
    />
  )
}

export default BetweenDatePicker
