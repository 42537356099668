import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 235px;
  height: 219px;
  padding: 20px;
  background: ${p => p.theme.colors.blocks?.light};
  border-radius: 24px;
`
export const TextsWrapper = styled.div`
  position: absolute;
  top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const InfoIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`
