import React, { useEffect, useState } from 'react'
import {
  AddTopicButtonWrapper,
  AddTopicWrapper,
  ButtonsWrapper,
  LoaderWrapper,
  ScrollWrapper,
  TopicsWrapper,
  TopicWrapper,
  Wrapper,
} from './styled'
import Text from 'Components/Kit/Text'
import Input from 'Components/Kit/Input'
import { useValidationState, validationFuncs } from 'Hooks/useValidatedState'
import Button from 'Components/Kit/Button'
import { css, useTheme } from 'styled-components'
import Cross from 'Assets/Cross'
import { ActiveStep, NewItem } from '../../types'
import { useFetch } from 'Hooks/useFetch'
import { TreeType } from 'Types/trees'
import { useRequest } from 'Hooks/useRequest'
import dayjs from 'dayjs'
import Loader from 'Components/Kit/Loader'
import { useTranslation } from 'Hooks/useTranslation'

type AddTopicsProps = {
  handleActiveStep: (step: ActiveStep) => void
  items: NewItem[]
  treeType: TreeType
  closeModal: () => void
}
const AddTopics = ({ handleActiveStep, items, treeType, closeModal }: AddTopicsProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const [[topicName, setTopicName], isTopicNameValid] = useValidationState<string>(
    '',
    validationFuncs.hasValue,
  )

  const { fetchData: fetchTopics, data: baseTopics } = useFetch<
    { name: string; description: string }[] | null
  >(
    `trees/predefined/?place=${encodeURIComponent(
      treeType.place,
    )}&type=${encodeURIComponent(treeType.type)}`,
    null,
    {},
    true,
  )

  const { requestData: createItem, isLoading } = useRequest()

  const [topics, setTopics] = useState<{ id: number; name: string }[]>([])

  useEffect(() => {
    if (baseTopics) {
      const updatedTopics = baseTopics.map((topic, index) => ({
        id: index,
        name: topic.name,
      }))
      setTopics(updatedTopics)
    }
  }, [baseTopics])
  const handleAddTopic = () => {
    const newTopic = { id: topics.length, name: topicName }
    setTopics([newTopic, ...topics])
    setTopicName('')
  }
  const handleRemoveTopic = (index: number) => {
    setTopics(topics.filter((_, idx) => idx !== index))
  }
  const handleCreateItem = async () => {
    const topicsTree = topics.map(topic => ({ name: topic.name }))
    const newLinks = items.map(linkItem => ({
      link: linkItem.link,
      since_date: linkItem.since_date
        ? dayjs(linkItem.since_date).format('YYYY-MM-DD')
        : null,
      parse_review_count:
        Number(linkItem.parse_review_count) > 0
          ? Number(linkItem.parse_review_count)
          : null,
    }))
    await createItem('POST', 'products/', {
      topics_data: topicsTree,
      cards_data: newLinks,
    })
    closeModal()
  }

  useEffect(() => {
    if (treeType.place && treeType.type) fetchTopics()
  }, [treeType.type, treeType.place])

  const isAddTopicDisabled = topics.length > 25

  return (
    <>
      <ScrollWrapper>
        {baseTopics ? (
          <>
            <Wrapper>
              <Text fontSize={14} fontWeight={400}>
                {t('AddItem.editTree')}
              </Text>
            </Wrapper>
            <AddTopicWrapper>
              <Input
                onChange={setTopicName}
                value={topicName}
                placeholder={t('AddItem.addTopicPlaceholder')}
                isValueValid={isTopicNameValid}
                fragment={css`
                  background: ${theme.colors.blocks.lightGray};
                `}
              />
              <AddTopicButtonWrapper>
                <Button onClick={handleAddTopic} disabled={isAddTopicDisabled}>
                  {t('AddItem.addTopic')}
                </Button>
              </AddTopicButtonWrapper>
            </AddTopicWrapper>
            <TopicsWrapper>
              {topics &&
                topics.map((topic, index) => (
                  <TopicWrapper key={index}>
                    <Text fontSize={14} fontWeight={400}>
                      {topic.name}
                    </Text>
                    <div onClick={() => handleRemoveTopic(index)}>
                      <Cross fill={theme.colors.icons.dark} rotate={45} />
                    </div>
                  </TopicWrapper>
                ))}
            </TopicsWrapper>
          </>
        ) : (
          <LoaderWrapper>
            <Loader size={25} color={theme.colors.blocks.dark} />
          </LoaderWrapper>
        )}
      </ScrollWrapper>
      <ButtonsWrapper>
        <Button
          disabled={isAddTopicDisabled}
          onClick={() => handleActiveStep(ActiveStep.SOURCES)}
          buttonSize={'large'}
          buttonType={'secondary'}
          fragment={css`width: 160px;`}
        >
          {t('AddItem.back')}
        </Button>
        <Button
          disabled={isAddTopicDisabled}
          onClick={handleCreateItem}
          buttonSize={'large'}
          fragment={css`width: 160px;`}
        >
          {isLoading ? (
            <Loader size={25} color={theme.colors.icons.light} />
          ) : (
            t('AddItem.title')
          )}
        </Button>
      </ButtonsWrapper>
    </>
  )
}

export default AddTopics
